import graphql from '@/imports/graphql'

export default {
  actions: {
    async getBatches(context, params) {
      let res = await graphql(`
        query GetBatches {
          batch_list(
            limit: 1000,
            page: 1,
            token: "${context.rootState.token}"
          ) {
            result {
              id
              amount
              usedCount
              productionDate
              availableCount
              version
              active
              productDescription
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.batch_list.result
    },
    async saveBatch(context, params) {
      let res = await graphql(`
        mutation SaveBatch($id: ObjectId, $productionDate: DateTime!, $productDescription: String, $amount: Int!, $active: Boolean, $version: Int) {
          batch_save(
            id: $id,
            productionDate: $productionDate,
            productDescription: $productDescription,
            amount: $amount,
            active: $active,
            version: $version,
            token: "${context.rootState.token}"
          ) {
            result {
              id
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.batch_save.result
    },
    async deleteBatch(context, params) {
      let res = await graphql(`
        mutation DeleteBatch($id: ObjectId!, $version: Int!) {
          batch_delete(
            id: $id,
            version: $version,
            token: "${context.rootState.token}"
          ) {
            result
            errors {
              message
            }
          }
        }
      `, params)
      return res
    },
    async getBatch(context, params) {
      let res = await graphql(`
        query GetBatch($id: ObjectId!) {
          batch_get(
            id: $id,
            token: "${context.rootState.token}"
          ) {
            result {
              id
              amount
              usedCount
              productionDate
              productDescription
              availableCount
              version
              active
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.batch_get.result
    }
  }
}
