<template>
  <div style="max-width: 500px; margin: 0 auto;">
    <v-breadcrumbs>
      <v-breadcrumbs-item exact :to="{name: 'Users'}">Users</v-breadcrumbs-item>
      <v-breadcrumbs-item>
        <template v-if="id">Edit user</template>
        <template v-else>Create user</template>
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="!loading">
      <v-form @submit.prevent="submit" v-model="valid" ref="form">
        <v-card-title>
          <div class="headline">
            <template v-if="id">Edit user</template>
            <template v-else>Create user</template>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-if="!id"
            :disabled="saving"
            label="User name *"
            v-model="form.userName"
            :rules="[rules.required]"
          ></v-text-field>
          <v-text-field
            v-if="!id"
            :disabled="saving"
            label="Password *"
            v-model="form.password"
            type="password"
            :rules="[rules.required]"
          ></v-text-field>
          <LocationPicker v-model="form.locationId"></LocationPicker>
          <v-text-field :disabled="saving" label="First name" v-model="form.firstName"></v-text-field>
          <v-text-field :disabled="saving" label="Middle name" v-model="form.middleName"></v-text-field>
          <v-text-field :disabled="saving" label="Last name" v-model="form.lastName"></v-text-field>
          <v-text-field :disabled="saving" label="Email" v-model="form.email" type="email"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="saving"
            type="submit"
            color="success"
            :disabled="!valid"
          >Save {{buttonAppend}}</v-btn>
          <v-btn :disabled="saving" :to="{name: 'Users'}" exact color="info">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-progress-linear v-else indeterminate></v-progress-linear>
  </div>
</template>

<script>
import rules from "@/mixins/rules"
import preventLeave from "@/mixins/preventLeave"
import md5 from "md5"
import LocationPicker from "@/components/common/LocationPicker"

export default {
  mixins: [rules, preventLeave],
  components: {
    LocationPicker
  },
  data() {
    return {
      loading: true,
      valid: false,
      id: null,
      saving: false,
      version: 0,
      form: {
        locationId: "",
        userName: "",
        password: "",
        firstName: "",
        lastName: "",
        middleName: "",
        email: ""
      }
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.saving = true
        this.preventLeave = false
        await this.$store.dispatch("saveUser", {
          passwordCheck: md5(this.form.userName + "|" + this.form.password),
          locationId: this.form.locationId,
          firstName: this.form.firstName,
          lastName: this.form.lastName,
          middleName: this.form.middleName,
          email: this.form.email,
          id: this.id,
          version: this.version
        })
        this.saving = false
        this.$router.push({ name: "Users" })
      }
    },
    async getUser() {
      let res = await this.$store.dispatch("getUser", {
        id: this.id
      })

      this.form.firstName = res.firstName
      this.form.middleName = res.middleName
      this.form.lastName = res.lastName
      this.form.email = res.email
      this.form.locationId = res.locationId
      this.version = res.version
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      await this.getUser()
    }

    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>
