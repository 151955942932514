import Vue from 'vue'
import Vuex from 'vuex'
// import axios from 'axios'
import graphql from '@/imports/graphql'
import VuexPersistence from 'vuex-persist'
import md5 from 'md5'

import batches from '@/store/batches'
import customers from '@/store/customers'
import packages from '@/store/packages'
import servers from '@/store/servers'
import users from '@/store/users'
import nanolinks from '@/store/nanolinks'
import assets from '@/store/assets'
import locations from '@/store/locations'
import documents from '@/store/documents'
import gates from '@/store/gates'
import trackers from '@/store/trackers'
import languages from '@/store/languages'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
  key: 'nanolink-admin',
  storage: window.localStorage,
  filter: mutation => mutation.type === 'loginAndSave' || mutation.type === 'logout'
})

const store = new Vuex.Store({
  state: {
    pretoken: '',
    token: '',
    loginTime: null,
    currentCustomer: null,
    notification: {
      show: false,
      message: '',
      color: ''
    }
  },
  modules: {
    batches,
    customers,
    packages,
    servers,
    users,
    nanolinks,
    assets,
    locations,
    documents,
    gates,
    languages,
    trackers
  },
  actions: {
    notify(context, { message, color }) {
      context.state.notification.show = true
      context.state.notification.message = message
      context.state.notification.color = color
    },
    async prelogin(context, params) {
      let res = await graphql(
        `
          query Login($md5: String!) {
            auth_login(pwdcheck: $md5) {
              result
              errors {
                message
                errorKey
              }
            }
          }
        `,
        {
          md5: md5(params.user + '|' + params.password)
        }
      )

      let pretoken = res.data.auth_login.result

      if (!pretoken) return false
      else {
        context.commit('prelogin', {
          token: pretoken
        })
        return res
      }
    },
    async setpin(context, params) {
      let res = await graphql(
        `
          query Setpin($pin: String!, $token: String!) {
            auth_setpin(pin: $pin, token: $token) {
              result
              errors {
                message
                errorKey
              }
            }
          }
        `,
        {
          pin: params.pin,
          token: params.token
        }
      )

      let token = res.data.auth_setpin.result

      if (!token) return false
      else {
        context.commit('login', {
          token: token
        })
        return res
      }
    },
    checkLoginTime(context) {
      if (context.state.loginTime && context.state.loginTime < Date.now() - 2592000000) {
        context.commit('logout')
      }
    }
  },
  mutations: {
    prelogin(state, params) {
      state.pretoken = params.token
    },
    login(state, params) {
      state.token = params.token
    },
    loginAndSave(state, params) {
      state.token = params.token
      state.loginTime = Date.now()
    },
    logout(state) {
      state.token = ''
      state.pretoken = ''
    }
  },
  plugins: [vuexLocal.plugin]
})

export default store
