import graphql from '@/imports/graphql'

export default {
  actions: {
    async initGate(context, params) {
      let res = await graphql(`
        mutation InitGate($vid: String!, $name: String!, $throttleInterval: Int, $customerId: String, $serverid:Int) {
          core_initializegate(
            vid: $vid,
            name: $name,
            throttleInterval: $throttleInterval,
            customerId: $customerId,
            serverid: $serverid,
            token: "${context.rootState.token}"
          ) {
            log
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.core_initializegate
    }
  }
}
