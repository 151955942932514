<template>
  <v-dialog v-model="dialog" width="500">
    <v-card>
      <v-card-title>
        <div class="title">Add file to package</div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-select
            item-text="productDescription"
            return-object
            v-model="batch"
            item-value="id"
            :items="batches"
            label="Select batch *"
            :rules="[rules.required]"
          >
            <template slot="item" slot-scope="data">
              <v-list-item-avatar>
                <v-chip color="primary" text-color="white">{{data.item.availableCount}}</v-chip>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{data.item.productDescription}}</v-list-item-title>
                <v-list-item-subtitle>{{formatDate(data.item.productionDate)}}</v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-select>

          <v-input ref="nanolinkInput" :value="nanolinks" :rules="[rules.requiredMultiple]">
            <v-flex>
              <FilePicker
                label="Select file with NanoLinks *"
                v-on:formData="parseForm"
                accept=".json"
              ></FilePicker>

              <template v-if="nanolinks.length === 0">
                <div class="body-2 text-xs-center">No NanoLinks added</div>
              </template>

              <template v-else>
                <div class="body-2 text-xs-center">{{nanolinks.length}} NanoLinks added</div>
                <div class="nanolink-list">
                  <v-list two-line>
                    <template v-for="(nanolink, index) in nanolinks">
                      <v-list-item :key="nanolink.ID">
                        <v-list-item-content>
                          <v-list-item-title>{{ nanolink.ID }}</v-list-item-title>
                          <v-list-item-subtitle>{{ formatDate(nanolink.LastContact) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="index + 1 < nanolinks.length" :key="index"></v-divider>
                    </template>
                  </v-list>
                </div>
              </template>
            </v-flex>
          </v-input>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn color="success" :disabled="!valid" @click="addFile">Add file</v-btn>
        <v-btn color="warning" @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FilePicker from "@/components/FilePicker"
import rules from "@/mixins/rules"
import formatDate from "@/mixins/formatDate"

export default {
  mixins: [rules, formatDate],
  components: {
    FilePicker
  },
  data() {
    return {
      valid: false,
      dialog: false,
      nanolinks: [],
      batches: [],
      batch: ""
    }
  },
  methods: {
    parseForm(form) {
      let file = form.getAll("data")[0]
      let reader = new FileReader()
      reader.addEventListener("load", () => {
        let parsedFile = reader.result
        let nanolinks = JSON.parse(parsedFile)
        this.nanolinks = nanolinks.concat(this.nanolinks)
      })
      reader.readAsText(file)
    },
    open() {
      this.nanolinks = []
      this.dialog = true
      if (typeof this.$refs.form !== "undefined") this.$refs.form.reset()
    },
    async getBatches() {
      this.batches = await this.$store.dispatch("getBatches")
    },
    addFile() {
      let file = {
        batchId: this.batch.id,
        batchDate: this.batch.productionDate,
        batchDescription: this.batch.productDescription,
        nanolinks: this.nanolinks
      }

      this.$emit("fileAdded", file)
      this.dialog = false
    }
  },
  mounted() {
    this.getBatches()
  }
}
</script>

<style scoped>
.nanolink-list {
  height: 300px;
  overflow-y: scroll;
}
</style>
