// define a mixin object
export default {
  computed: {
    rules() {
      return {
        deviceName: v => /^[a-zA-Z0-9]+$/i.test(v) && v.length <= 6 || 'Only alphanumeric input and MAX 6 chars long',
        throttleInterval: v => (v >= 20 && v <= 3600) || 'Required between 20 and 3600',
        required: v => (v === 0 || (!!v && !!v.toString().trim().length)) || 'Required',
        requiredService: v => !!v || 'Required',
        requiredMultiple: v => !!v.length || 'Required',
        phoneunique: () => this.phoneunique || 'Unique phone number',
        notNegative: v => (v >= 0) || 'Not negative',
        onlyPhoneNumbers: v => /^[0-9 ]+$/i.test(v) || 'Only humbers',
        // eslint-disable-next-line no-useless-escape
        email: v => /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i.test(v) || 'Invalid email',
        // eslint-disable-next-line no-useless-escape
        url: v => /[-a-zA-Z0-9@:%_\+.~#?&//=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&//=]*)?/gi.test(v) || 'Incorrect URL format'
      }
    }
  }
}
