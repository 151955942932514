import moment from 'moment'

export default {
  methods: {
    formatDate (date) {
      if (!date) return false
      return moment(date).format('YYYY/MM/DD, H:mm')
    }
  }
}
