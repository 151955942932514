<template>
  <div>
    <v-card>
      <v-tabs color="primary" slider-color="green">
        <v-tab ripple>Search users</v-tab>
        <v-tab ripple>Search nanolinks</v-tab>
        <v-tab ripple>Search assets</v-tab>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <SearchUsers :server-list="serverList"></SearchUsers>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <SearchNanolinks :server-list="serverList"></SearchNanolinks>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <SearchAssets :server-list="serverList"></SearchAssets>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
import SearchUsers from '@/components/search/tabs/SearchUsers'
import SearchNanolinks from '@/components/search/tabs/SearchNanolinks'
import SearchAssets from '@/components/search/tabs/SearchAssets'
export default {
  components: {
    SearchUsers,
    SearchNanolinks,
    SearchAssets
  },
  data() {
    return {
      serverList: []
    }
  },
  async created() {
    this.serverList = await this.$store.dispatch('getServers')
  }
}
</script>
