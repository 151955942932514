import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.login.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCard,[_c(VCardTitle,[_c(VIcon,{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("nl-icon")]),_c('div',{staticClass:"title text-uppercase font-weight-light",staticStyle:{"font-size":"15px !important","padding-top":"3px"}},[_vm._v(" Superadmin login (Legacy) ")])],1),_c(VDivider),_c(VCardText,[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":"User *"},model:{value:(_vm.user),callback:function ($$v) {_vm.user=$$v},expression:"user"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.required],"type":"password","label":"Password *"},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}})],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"loading":_vm.loading,"disabled":!_vm.valid,"type":"submit","color":"primary"}},[_vm._v("Login")])],1)],1)],1)],1),_c('div',{staticStyle:{"position":"fixed","right":"10px","top":"10px"}},[_c('p',{staticClass:"caption pa-0",staticStyle:{"text-align":"right"}},[_c('span',{staticClass:"grey--text"},[_vm._v("Environment:")]),_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(_vm.appEnv)}}),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v("Version:")]),_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(_vm.appVersion)}}),_c('br'),_c('span',{staticClass:"grey--text"},[_vm._v("Revision:")]),_c('span',{staticClass:"grey--text",domProps:{"textContent":_vm._s(_vm.appRevision)}})])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }