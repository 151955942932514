<template>
  <v-select :label="label" :items="langAvailable" v-model="lang" item-value="lang"></v-select>
</template>

<script>
export default {
  props: ["value", "label"],
  data() {
    return {
      lang: this.value,
      langAvailable: []
    };
  },
  watch: {
    lang(val) {
      this.$emit("input", val);
    },
    value(val) {
      this.lang = val;
    }
  },
  async mounted() {
    let languages = await this.$store.dispatch("getLanguages");
    this.langAvailable = languages;
  }
};
</script>
