import { VBreadcrumbs } from 'vuetify/lib/components/VBreadcrumbs';
import { VBreadcrumbsItem } from 'vuetify/lib/components/VBreadcrumbs';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBreadcrumbs,[_c(VBreadcrumbsItem,{attrs:{"exact":"","to":{name: 'Customers'}}},[_vm._v("Customers")]),_c(VBreadcrumbsItem,[_vm._v("Customer name")]),_c(VBreadcrumbsItem,[_vm._v("Add NanoLinks")])],1),_c('FilePicker',{attrs:{"accept":".json"},on:{"formData":_vm.parseForm}}),(_vm.nanolinks)?_c(VCard,[_c(VList,{attrs:{"two-line":"","subheader":""}},[_c(VSubheader,[_vm._v(_vm._s(_vm.nanolinks.length)+" NanoLinks in file")]),_vm._l((_vm.nanolinks),function(nanolink,index){return [_c(VListItem,{key:nanolink.ID},[_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(nanolink.ID))]),_c(VListItemSubtitle,[_vm._v(_vm._s(_vm.formatDate(nanolink.LastContact)))])],1)],1),(index + 1 < _vm.nanolinks.length)?_c(VDivider,{key:index}):_vm._e()]})],2)],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }