<template>
  <div>
    <div
      v-if="state === 'scanning'"
      class="pa-3 font-weight-light"
    >Fit the QR code in the green rectangle</div>
	
    <div class="qr-container">
      <video v-if="state === 'scanning'" ref="video" playsinline></video>
      <v-btn v-else-if="state === 'init'" color="primary" @click="getCameras">Scan</v-btn>
      <div v-else-if="state === 'waiting'">Waiting for camera</div>
      <div v-else-if="state === 'waitingstream'">Waiting for stream</div>
      <v-list v-else-if="state === 'picking'">
        <v-subheader>Select camera</v-subheader>
        <v-list-item v-for="(camera, index) in cameras" :key="index" @click="selectCamera(camera)">
          <v-list-item-icon>
            <v-icon>camera_alt</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>{{ camera.label }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <div v-else-if="state==='success'">
        <v-icon size="200" color="primary">check_circle_outline</v-icon>
      </div>
      <div v-show="state === 'scanning'" class="target">
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          xmlns:xlink="http://www.w3.org/1999/xlink"
          x="0"
          y="0"
          viewBox="0 0 1000 1000"
          enable-background="new 0 0 1000 1000"
          xml:space="preserve"
        >
          <g>
            <g transform="translate(0.000000,511.000000) scale(0.100000,-0.100000)">
              <path
                d="M1521.2,4961.4c-622.9-116-1105.7-534-1329.5-1151.5c-70.1-191.5-72.8-215.7-80.9-943.9L100,2116.4h285.9h285.9v601.4c0,641.8,29.7,863,134.8,1070.6c72.8,142.9,275.1,364.1,415.3,450.4c226.5,145.6,277.8,153.7,1040.9,169.9l714.6,13.5v283.2v283.2l-633.7,5.4C1917.6,4996.5,1647.9,4985.7,1521.2,4961.4z"
              />
              <path
                d="M7009.1,4710.7v-299.3l722.7-8.1l720-8.1l180.7-86.3c253.5-118.7,431.5-293.9,555.5-544.7l99.8-202.3l8.1-722.7l8.1-722.7h299.4H9900l-13.5,722.7c-8.1,547.4-21.6,755.1-56.6,868.4c-169.9,593.3-636.4,1059.8-1229.7,1229.7c-113.3,35.1-320.9,48.5-868.4,56.6l-722.7,13.5V4710.7z"
              />
              <path
                d="M105.4-2567.9c0-779.4,24.3-933.1,191.5-1275.6c156.4-315.5,436.9-596,752.4-752.4c342.5-167.2,496.2-191.5,1275.6-191.5h666.1v283.2v283.2h-585.2c-323.6,0-658,10.8-747,27c-485.4,78.2-868.4,453.1-960,941.2c-13.5,80.9-27,415.3-27,747v604.1H388.6H105.4V-2567.9z"
              />
              <path
                d="M9320.2-1918c-10.8-10.8-18.9-312.8-18.9-671.5c0-849.5-43.1-1000.5-358.7-1302.5c-299.4-280.5-493.5-329-1332.2-329h-601.4v-283.2v-283.2h663.4c512.4,0,703.9,10.8,854.9,43.1c520.5,118.7,930.4,436.9,1167.7,908.8c167.2,329,183.4,436.9,186.1,1218.9v701.2l-272.4,8.1C9460.4-1904.5,9331-1907.2,9320.2-1918z"
              />
            </g>
          </g>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
import QrScanner from 'qr-scanner'
import { isMobile } from 'mobile-device-detect'
QrScanner.WORKER_PATH = 'qr-scanner-worker.min.js'

export default {
	props: {
		dialog: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			showVideo: false,
			cameras: [],
			state: 'init',
			qr: '',
			qrScanner: null,
			beep: {}
		}
	},
	destroyed() {
		this.stop()
	},
	watch: {
		dialog(val) {
			if (!val) {
				this.stop()
				this.state = 'init'
			}
		}
	},
	mounted() {
		this.beep = new Audio('audio/beep.mp3')
	},
	methods: {
		stop() {
			if (this.qrScanner) {
				this.qrScanner.stop()
				this.qrScanner.destroy()
				this.qrScanner = null

			}
		},
		scanned(result) {
			// this.state = "scanned"
			this.beep.play()
			this.stop()
			this.state = 'success'
			let scanned = {
				qr: result,
				step: '2'
			}
			this.$emit('scanned', scanned)
			setTimeout(() => this.state = 'init', 1000)
		},
		startScan(stream) {
			const video = this.$refs.video
			video.srcObject = stream

			this.qrScanner = new QrScanner(video, result => this.scanned(result))
			this.qrScanner.start()
		},
		async selectCamera(camera) {
			this.state = 'waitingstream'
			let stream

			if (isMobile) {
				stream = await navigator.mediaDevices.getUserMedia({
					video: {
						facingMode: camera.facingMode,
						width: { ideal: 1280 },
						height: { ideal: 720 }
					}
				})
			} else {
				stream = await navigator.mediaDevices.getUserMedia({
					video: {
						deviceId: camera.deviceId,
						width: { ideal: 1280 },
						height: { ideal: 720 }
					}
				})
			}

			this.state = 'scanning'

			this.$nextTick(() => {
				this.startScan(stream)
			})
		},
		async getCameras() {
			this.state = 'waiting'

			if (isMobile) {
				this.cameras = [
					{
						label: 'Front facing',
						facingMode: 'user'
					},
					{
						label: 'Back facing',
						facingMode: 'environment'
					}
				]
				this.state = 'picking'
			} else {
				const devices = await navigator.mediaDevices.enumerateDevices()
				const videoDevices = devices.filter(
					device => device.kind === 'videoinput'
				)
				if (videoDevices.length === 1) {
					this.selectCamera(videoDevices[0].deviceId)
				} else {
					this.cameras = videoDevices
					this.state = 'picking'
				}
			}
		}
	}
}
</script>

<style scoped>
video {
  height: 100%;
}

.qr-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 300px;
  padding: 1.2rem;
}
.target {
  position: absolute;
}
.target > svg {
  width: 130px;
  opacity: 0.8;
  fill: #6aa641;
}
.qr {
  height: 100%;
  image-rendering: pixelated;
}
</style>
