<template>
  <div>
    <PackageScanner ref="packageScanner"></PackageScanner>

    <v-btn color="primary" to="/locations/create">Create location</v-btn>

    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="locations"
      class="elevation-1"
      @update="pagination"
      :search="search"
      item-key="id"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.name }}</td>
          <td>{{ props.item.address1 + props.item.address2 }}</td>
          <td>{{ props.item.zipCode }}</td>
          <td>{{ props.item.city }}</td>
          <td>{{ props.item.country }}</td>
        </tr>
      </template>

      <template slot="expand" slot-scope="props">
        <v-card class="pa-2">
          <v-btn color="warning" :to="{name: 'LocationEdit', params: {id: props.item.id}}">Edit</v-btn>
          <v-btn @click="deleteLocation(props.item.id, props.item.version)" color="error">Delete</v-btn>
          <v-btn @click="assignPackages(props.item)" color="success">Assign packages</v-btn>
        </v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import PackageScanner from "@/components/common/PackageScanner"

export default {
  components: {
    PackageScanner
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Name", value: "name" },
        { text: "Address", value: "address1" },
        { text: "ZIP", value: "zipCode" },
        { text: "City", value: "city" },
        { text: "Country", value: "country" }
      ],
      locations: [],
      pagination: {
        sortBy: "name",
        rowsPerPage: 25
      }
    }
  },
  mounted() {
    this.getLocations()
  },
  methods: {
    async getLocations() {
      let locations = await this.$store.dispatch("getLocations")
      this.locations = locations || []
    },
    async deleteLocation(id, version) {
      let res = await this.$root.$confirmDelete(
        "Delete location",
        `Are you sure you want to delete the location?`
      )
      if (res) {
        await this.$store.dispatch("deleteLocation", {
          id: id,
          version: version
        })
        this.getLocations()
      }
    },
    async assignPackages(location) {
      let res = await this.$refs.packageScanner.scan()
      if (res) {
        try {
          for (const p of res) {
            await this.$store.dispatch("relocatePackage", {
              packageId: p.id,
              newLocationId: location.id,
              version: p.version
            })
          }
          this.$store.dispatch("notify", {
            message: "Packages relocated",
            color: "success"
          })
        } catch (e) {
          this.$store.dispatch("notify", {
            message: "Error relocating package",
            color: "error"
          })
        }
        this.$refs.packageScanner.close()
      }
    }
  }
}
</script>
