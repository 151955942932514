import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSubheader } from 'vuetify/lib/components/VSubheader';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('div',{staticClass:"title"},[_vm._v("Scan packages")])]),_c(VCardText,[_c('QrScanner',{on:{"scanned":function($event){_vm.packageId = $event.qr}}}),_c(VList,{attrs:{"subheader":""}},[(!_vm.packages.length)?_c(VSubheader,[_vm._v("No packages scanned")]):_c(VSubheader,[_vm._v(_vm._s(_vm.packages.length)+" package(s) scanned")]),_vm._l((_vm.packages),function(p,index){return _c(VListItem,{key:p.id},[_c(VListItemAvatar,[_c(VIcon,{staticClass:"grey lighten-1 white--text"},[_vm._v("archive")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v(_vm._s(p.id))])],1),_c(VListItemAction,{on:{"click":function($event){return _vm.removePackage(index)}}},[_c(VBtn,{attrs:{"icon":"","ripple":""}},[_c(VIcon,{attrs:{"color":"error"}},[_vm._v("clear")])],1)],1)],1)})],2)],1),_c(VCardActions,[_c(VBtn,{attrs:{"loading":_vm.saving,"color":"success","disabled":!_vm.packages.length},on:{"click":_vm.done}},[_vm._v("Done")]),_c(VBtn,{attrs:{"disabled":_vm.saving},on:{"click":_vm.cancel}},[_vm._v("Cancel")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }