<template>
  <div>
    <v-card>
      <v-tabs color="primary" slider-color="green">
        <v-tab ripple>GPS Tracker</v-tab>
        <v-tab ripple>GPS Gate</v-tab>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <GPSTracker></GPSTracker>
            </v-card-text>
          </v-card>
        </v-tab-item>

        <v-tab-item>
          <v-divider></v-divider>
          <v-card flat>
            <v-card-text>
              <GPSGate></GPSGate>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>
<script>
import GPSTracker from "@/components/gates/GPSTracker"
import GPSGate from "@/components/gates/GPSGate"
export default {
  components: {
    GPSTracker, GPSGate
  },

}
</script>