<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title>
        <div class="title">Initialize gate</div>
      </v-card-title>
      <v-card-text>
        <v-stepper v-model="step">
          <v-stepper-header>
            <v-stepper-step step="1">Scan gate</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="2">Initialize gate</v-stepper-step>
            <v-divider></v-divider>
            <v-stepper-step step="3">Status</v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <v-container class="pa-0">
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-card>
                      <v-card>
                        <QrScanner @scanned="vId = $event.qr, step = $event.step"></QrScanner>
                      </v-card>
                    </v-card>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="2">
              <v-container class="pa-0" grid-list-sm>
                <v-layout row wrap>
                  <v-flex xs12>
                    <v-form v-model="validStepTwo">
                      <div class="pa-5">
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="deviceName"
                              required
                              :rules="[rules.deviceName]"
                              label="Device name *"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <div class="body-2 px-4 grey--text">Throttle interval (20 - 3600)</div>
                          </v-col>
                        </v-row>
                        <NumberInput :throttle-val.sync="throttleInterval"></NumberInput>
                      </div>
                      <v-card-actions>
                        <v-btn
                          class="ma-2"
                          :disabled="!validStepTwo"
                          color="primary"
                          @click="step='3'"
                        >Next</v-btn>
                        <v-spacer></v-spacer>
                      </v-card-actions>
                    </v-form>
                  </v-flex>
                </v-layout>
              </v-container>
            </v-stepper-content>

            <v-stepper-content step="3">
              <v-container class="pa-0" grid-list-sm>
                <v-layout row wrap>
                  <v-flex xs12>
                    <span class="mr-2 black--text">VID:</span>
                    <span>{{vId}}</span>
                  </v-flex>
                  <v-flex xs12>
                    <span class="mr-2 black--text">Device name:</span>
                    <span>{{deviceName}}</span>
                  </v-flex>
                  <v-flex xs12>
                    <span class="mr-2 black--text">Throttle interval:</span>
                    <span>{{throttleInterval}}</span>
                  </v-flex>
                        <v-flex xs12>
                          <v-btn class="secondary" @click="cleanForm">Scan again</v-btn>
                        </v-flex>
                </v-layout>
              </v-container>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>
      </v-card-text>
      <v-card-actions class="px-6 pb-6">
        <v-btn :loading="saving" color="success" :disabled="step !== '3'" @click="done">Initialize</v-btn>
        <v-btn :disabled="saving" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import QrScanner from '@/components/scanners/QrScanner'
import rules from '@/mixins/rules'
import record from '@/mixins/record'
import NumberInput from '@/components/common/NumberInput'

export default {
	components: { QrScanner, NumberInput },
	mixins: [rules, record],
	data() {
		return {
			initializing: false,
			validStepTwo: false,
			vId: '',
			throttleInterval: 120,
			step: '1',
			saving: false,
			dialog: false,
			resolve: null,
			reject: null,
			tempDeviceName: ''
		}
	},
	computed: {
		deviceName: {
			get() {
				return this.vId.split('').slice(6).join('')
			}
		}
	},
	methods: {
		cleanForm() {
			this.step = '1'
			this.throttleInterval = 120
			this.vId = ''
		},
		scan() {
			this.dialog = true

			return new Promise((resolve, reject) => {
				this.resolve = resolve
				this.reject = reject
			})
		},
		close() {
			this.dialog = false
			this.saving = false
			this.cleanForm()
		},
		cancel() {
			this.close()
			this.resolve(false)
			this.cleanForm()
		},
		done() {
			const newGate = {
				vId: this.vId,
				deviceName: this.deviceName,
				throttleInterval: this.throttleInterval,
				customerId: this.$store.state.currentCustomer.id,
				serverId: this.$store.state.currentCustomer.serverID
			}
			this.resolve(newGate)
			this.saving = true
			// this.close()
		}
	}
}
</script>

