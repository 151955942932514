<template>
  <v-dialog v-model="show" max-width="500">
    <v-card>
      <v-card-title>
        <div class="title">Deploying document {{doc.documentName}}</div>
      </v-card-title>
      <v-card-text>
        <v-list>
          <v-list-item v-for="server in servers" :key="server.id">
            <v-list-item-action>
              <v-icon v-if="server.done" color="success">check</v-icon>
              <v-icon v-else-if="server.error" color="error">error_outline</v-icon>
              <v-progress-circular v-else :width="3" :size="20" indeterminate color="grey"></v-progress-circular>
            </v-list-item-action>

            <v-list-item-content>
              <v-list-item-title v-text="server.name"></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
// GPS
// Fundet af
// Seneste gate
// Markeret
export default {
  data() {
    return {
      doc: {},
      show: false,
      servers: []
    }
  },
  methods: {
    async open(doc) {
      this.doc = doc
      this.show = true

      this.servers = await this.$store.dispatch("getServers")

      for (let server of this.servers) {
        try {
          let res = await this.$store.dispatch("deployDocument", {
            serverId: server.id,
            docId: this.doc.id
          })

          if (res) this.$set(server, "done", true)
          else this.$set(server, "error", true)
        } catch (e) {
          this.$set(server, "error", true)
        }
      }
    }
  }
}
</script>
