<template>
  <div>
    <v-btn color="primary" @click="dialog = true">Add GPS Gate</v-btn>
    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="trackersList"
      class="elevation-1"
      :search="search"
      item-key="id"
      @update="pagination"
    ></v-data-table>
    <v-dialog v-model="dialog" max-width="750">
      <v-card>
        <GateScanner :dialog="dialog" @closeDialog="dialog = false, getTrackers()"></GateScanner>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import GateScanner from '@/components/gates/GateScanner'
export default {
	components: {
		GateScanner
	},
	data() {
		return {
			headers: [
				{ text: 'Model', value: 'additionalInfo.trackerModel' },
				{ text: 'SIM card id', value: 'additionalInfo.iCCID' },
				{ text: 'Vid', value: 'vID' },
				{ text: 'Pid', value: 'pID' },
				{ text: 'Customer id', value: 'customerId' }
			],
			dialog: false,
			search: '',
			trackersList: [],
			pagination: {
				sortBy: 'id',
				rowsPerPage: 25
			}
		}
	},
	mounted() {
		this.getTrackers()
	},
	methods: {
		async getTrackers() {
			this.trackersList = await this.$store.dispatch('getTrackers', {
				type: 'GPSGATE'
			})
		}
	}
}
</script>