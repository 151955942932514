import graphql from '@/imports/graphql'

export default {
  actions: {
    async findNanolinks(context, params) {
      let res = await graphql(
        `
         query FindNanolinks($serverId: Int!, $search: String, $customers: [ObjectId!]) {
          core_findnanolink(serverid: $serverId, search: $search, customers: $customers, token: "${context.rootState.token}") {

              result {
                customerId
                asset {
                  brand
                  model
                  id
                  serial
                }
              }
            errors {
              message
            }
          }
        }
      `,
        params
      )
      return res.data.core_findnanolink.result
    }
  }
}
