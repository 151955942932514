<template>
  <v-app>
    <ConfirmLeave ref="confirmLeave"></ConfirmLeave>
    <ConfirmDelete ref="confirmDelete"></ConfirmDelete>
    <SnackBar ref="snackBar"></SnackBar>

    <template v-if="$store.state.token === ''">
      <Login v-if="$store.state.pretoken === ''"></Login>
      <SetPin v-else></SetPin>
    </template>

    <template v-else>
      <v-navigation-drawer app>
        <Navigation></Navigation>
        <div style="position: fixed; bottom: 0">
          <p class="caption pa-4">
            <span class="grey--text">Environment: </span
            ><span class="grey--text" v-text="appEnv"></span>
            <br />
            <span class="grey--text">Version: </span
            ><span class="grey--text" v-text="appVersion"></span>
            <br />
            <span class="grey--text">Revision: </span
            ><span class="grey--text" v-text="appRevision"></span>
          </p>
        </div>
      </v-navigation-drawer>
      <v-content>
        <v-container>
          <router-view />
        </v-container>
      </v-content>
    </template>
  </v-app>
</template>

<script>
import ConfirmLeave from '@/components/ConfirmLeave'
import ConfirmDelete from '@/components/ConfirmDelete'
import SnackBar from '@/components/SnackBar'
import Login from '@/components/Login'
import SetPin from '@/components/SetPin'
import Navigation from '@/components/Navigation'

export default {
  components: {
    ConfirmLeave,
    SnackBar,
    Login,
    SetPin,
    ConfirmDelete,
    Navigation,
  },
  mounted() {
    this.$root.$confirmLeave = this.$refs.confirmLeave.open
    this.$root.$confirmDelete = this.$refs.confirmDelete.open
    //this.$root.$notify = this.$refs.snackBar.notify //is now done thorugh state
    this.$store.dispatch('checkLoginTime')
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_BUILDVERSION + '.' + process.env.VUE_APP_BUILDNUMBER
    },
    appRevision() {
      return process.env.VUE_APP_BUILDREVISIONSHORT
    },
    appEnv() {
      return process.env.NODE_ENV
    },
  },
}
</script> 
