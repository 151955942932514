<template>
  <div>
    <v-row>
      <v-col cols="4" sm="2">
        <v-btn class="mx-2" fab dark small color="primary" @click="input --">
          <v-icon dark>mdi-minus</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="4" sm="3">
        <v-text-field
          v-mask="mask"
          :rules="[rules.throttleInterval]"
          v-model="input"
          label
          clearable
        ></v-text-field>
      </v-col>
      <v-col cols="4" sm="2">
        <v-btn class="mx-2" fab dark small color="primary" @click="input ++">
          <v-icon dark>mdi-plus</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import rules from "@/mixins/rules"
import { mask } from "vue-the-mask"

export default {
  mixins: [rules],
  props: ["throttleVal"],
  directives: {
    mask
  },
  data() {
    return {
      mask: "####",
      input: 0
    }
  },
  watch: {
    input(val) {
      this.$emit("update:throttleVal", val)
    },
    throttleVal: {
      handler(val) {
        this.input = val
      },
      immediate: true
    }
  }
}
</script>
