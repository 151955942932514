<template>
  <div>
    <AssignPackage ref="assignPackage"></AssignPackage>

    <v-btn color="primary" to="/packages/create">Create package</v-btn>

    <v-dialog v-model="printing" width="500">
      <v-card>
        <v-card-title>
          <div class="title">Printing label</div>
        </v-card-title>
        <v-card-text>
          <v-progress-linear indeterminate></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="packages"
      class="elevation-1"
      @update="pagination"
      :expanded="expanded"
      :search="search"
      single-expand
      item-key="id"
      @click:row="clickedTableRow"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.customerId }}</td>
          <td>{{ props.item.locationId }}</td>
          <td>{{ props.item.createdDateTime }}</td>
          <td class="text-xs-right">{{ props.item.count }}</td>
          <td class="text-xs-right">{{ props.item.nominalCount }}</td>
        </tr>
      </template>

      <template v-slot:expanded-item="props">
        <div class="d-flex flex-row pa-2">
          <v-btn
            class="mx-1 px-4"
            color="warning"
            :to="{name: 'PackageEdit', params: {id: props.item.id}}"
          >Edit</v-btn>
          <v-btn
            class="mx-1 px-4"
            :disabled="props.item.count !== props.item.nominalCount"
            color="success"
            @click="printLabel(props.item)"
          >Print label</v-btn>
          <!-- <v-btn @click="$refs.assignPackage.open(props.item.id, props.item.version)" color="success">Assign to customer</v-btn> -->
        </div>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import AssignPackage from "@/components/AssignPackage"
import clickedTableRow from "@/mixins/clickedTableRow"
import axios from "axios"
import FileSaver from "file-saver"

export default {
  mixins: [clickedTableRow],
  components: {
    AssignPackage
  },
  data() {
    return {
      printing: false,
      expanded: [],
      search: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Customer ID", value: "customerId" },
        { text: "Location ID", value: "locationId" },
        { text: "Created", value: "createdDateTime" },
        { text: "Count", value: "count", align: "right" },
        { text: "Nominal count", value: "nominalCount", align: "right" }
      ],
      packages: [],
      pagination: {
        sortBy: "createdDateTime",
        descending: true,
        rowsPerPage: 25
      }
    }
  },
  mounted() {
    this.getPackages()
  },
  methods: {
    async getPackages() {
      let packages = await this.$store.dispatch("getPackages")
      this.packages = packages || []
    },
    async deletePackage(id, version) {
      let res = await this.$root.$confirmDelete(
        "Delete package",
        `Are you sure you want to delete the package?`
      )
      if (res) {
        await this.$store.dispatch("deletePackage", {
          id: id,
          version: version
        })
        this.getPackages()
      }
    },
    async printLabel(p) {
      this.printing = true
      let res = await axios.post(
        "https://labelprint.nanolink.com",
        {
          packageId: p.id,
          packagedDate: p.createdDateTime.split("T")[0],
          amount: p.nominalCount,
          productionDate: p.files[0].batch.createdDateTime.split("T")[0],
          type: p.files[0].batch.batchDescription.split("-")[0]
        },
        {
          responseType: "blob"
        }
      )
      this.printing = false
      FileSaver.saveAs(new Blob([res.data], { type: "image/png" }), "label.png")
    }
  }
}
</script>
