<template>
  <div>
    <DeployDocument ref="deployDocument"></DeployDocument>

    <v-btn color="primary" to="/documents/create">Create document</v-btn>

    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="documents"
      class="elevation-1"
      @update="pagination"
      :search="search"
      item-key="id"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.reportId }}</td>
          <td>{{ props.item.locationId }}</td>
          <td>{{ props.item.serverID }}</td>
          <td>{{ props.item.forAdminUser }}</td>
          <td>{{ props.item.forFinancialUser }}</td>
          <td>{{ props.item.createdDateTime }}</td>
        </tr>
      </template>

      <template slot="expand" slot-scope="props">
        <v-card class="pa-2">
          <v-btn color="primary" @click="$refs.deployDocument.open(props.item)">Deploy</v-btn>
        </v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import DeployDocument from "@/components/DeployDocument"

export default {
  components: {
    DeployDocument
  },
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Report ID", value: "reportId" },
        { text: "Location ID", value: "locationId" },
        { text: "Server ID", value: "serverID" },
        { text: "For admins", value: "forAdminUser" },
        { text: "For financial", value: "forFinancialUser" },
        { text: "Created", value: "createdDateTime" }
      ],
      documents: [],
      pagination: {
        sortBy: "createdDateTime",
        rowsPerPage: 25,
        descending: true
      }
    }
  },
  mounted() {
    this.getDocuments()
  },
  methods: {
    async getDocuments() {
      this.documents = await this.$store.dispatch("getDocuments")
    },
    async deleteLocation(id, version) {
      let res = await this.$root.$confirmDelete(
        "Delete location",
        `Are you sure you want to delete the location?`
      )
      if (res) {
        await this.$store.dispatch("deleteLocation", {
          id: id,
          version: version
        })
        this.getLocations()
      }
    }
  }
}
</script>
