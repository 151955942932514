<template>
  <div style="max-width: 500px; margin: 0 auto;">
    <v-breadcrumbs class="d-flex justify-space-between">
      <div>
        <v-breadcrumbs-item exact :to="{name: 'Customers'}">Customers</v-breadcrumbs-item>
        <v-breadcrumbs-item>
          <template v-if="id">{{form.name}}</template>
          <template v-else>Create customer</template>
        </v-breadcrumbs-item>
      </div>

      <v-btn class="mx-1 px-4" color="error" @click="deleteCustomer(id, form.serverId, )">Delete</v-btn>
    </v-breadcrumbs>
    <v-card v-if="!loading">
      <v-form @submit.prevent="submit" v-model="valid" ref="form">
        <v-card-title>
          <div class="headline">
            <template v-if="id">Edit customer</template>
            <template v-else>Create customer</template>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="form.name" :rules="[rules.required]" label="Company name *"></v-text-field>
          <v-text-field v-model="form.vat" label="VAT"></v-text-field>
          <v-select
            :rules="[rules.required]"
            :disabled="!!id"
            item-text="name"
            item-value="id"
            :items="servers"
            v-model="form.serverId"
            label="Server *"
          ></v-select>
          <LanguageSelector v-if="!id" label="Set group language" v-model="form.groupLanguage"></LanguageSelector>
          <LocationPicker :rules="[rules.required]" label="Location *" v-model="form.location"></LocationPicker>
          <template v-if="!id">
            <div class="subheading font-weight-bold">Send email to first user</div>
            <v-text-field v-model="form.firstName" label="First name"></v-text-field>
            <v-text-field v-model="form.middleName" label="Middle name"></v-text-field>
            <v-text-field v-model="form.lastName" label="Last name"></v-text-field>
            <v-text-field v-model="form.email" label="Email address"></v-text-field>
            <LanguageSelector label="Email language" v-model="form.language"></LanguageSelector>
          </template>
          <!-- <v-text-field :disabled="saving" v-model="form.messages" :rules="[rules.required]" type="number" label="Messages *"></v-text-field>
          <v-text-field :disabled="saving" v-model="form.price" :rules="[rules.required]" type="number" label="Price per message *" suffix="DKK"></v-text-field>-->
          <!-- <v-checkbox :disabled="saving" v-model="form.reset" label="Reset messages" class="mt-0"></v-checkbox> -->
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="saving"
            type="submit"
            color="success"
            :disabled="!valid"
          >Save {{buttonAppend}}</v-btn>
          <v-btn :disabled="saving" :to="{name: 'Customers'}" exact color="info">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-progress-linear v-else indeterminate></v-progress-linear>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
import preventLeave from '@/mixins/preventLeave'
import LocationPicker from '@/components/common/LocationPicker'
import LanguageSelector from '@/components/LanguageSelector'

export default {
  mixins: [rules, preventLeave],
  components: {
    LocationPicker,
    LanguageSelector
  },
  data() {
    return {
      loading: true,
      valid: false,
      id: null,
      saving: false,
      servers: [0, 1],
      form: {
        name: '',
        vat: '',
        messages: '0',
        price: '0',
        reset: false,
        serverId: null,
        location: '',
        language: 'en',
        groupLanguage: 'en',
        firstName: '',
        middleName: '',
        lastName: '',
        email: ''
      }
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.saving = true
        this.preventLeave = false
        await this.$store.dispatch('saveCustomer', {
          id: this.id,
          companyName: this.form.name,
          vAT: this.form.vat,
          locationId: this.form.location,
          language: this.form.groupLanguage,
          firstUserFirstName: this.form.firstName,
          firstUserMiddleName: this.form.middleName,
          firstUserLastName: this.form.lastName,
          welcomeEmailAddress: this.form.email,
          welcomeEmailLanguage: this.form.language,
          // detectorSMSCount: this.form.messages,
          // detectorSMSPriceTotal: this.form.price,
          serverId: this.form.serverId
        })
        this.saving = false
        this.$router.push({ name: 'Customers' })
      }
    },
    async getCustomer() {
      let res = await this.$store.dispatch('getCustomer', {
        id: this.id,
        serverId: this.form.serverId
      })

      let data = res

      this.form.name = data.companyName
      this.form.vat = data.vAT
      this.form.location = data.locationId
      this.form.messages = data.detectorSMSCount
      this.form.price = data.detectorSMSPriceTotal
      this.form.serverId = data.serverID
    },
    async deleteCustomer(id, serverID, customer) {
      let res = await this.$root.$confirmDelete(
        'Delete customer',
        `Are you sure you want to delete the customer <b>${customer}</b>?`
      )
      if (res) {
        await this.$store.dispatch('deleteCustomer', {
          customerId: id,
          serverId: serverID
        })
        this.$router.push({ name: 'Customers' })
      }
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      this.form.serverId = this.$route.params.serverId
      await this.getCustomer()
    }

    this.servers = await this.$store.dispatch('getServers')

    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>
