<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title>
        <div class="title">Resend welcome email</div>
      </v-card-title>
      <v-form @submit.prevent="submit" v-model="valid" ref="form">
        <v-card-text>
          <v-text-field :rules="[rules.required]" v-model="form.firstName" label="First name *"></v-text-field>
          <v-text-field v-model="form.middleName" label="Middle name"></v-text-field>
          <v-text-field :rules="[rules.required]" v-model="form.lastName" label="Last name *"></v-text-field>
          <v-text-field
            :rules="[rules.required, rules.email]"
            v-model="form.email"
            label="Email address *"
          ></v-text-field>
          <LanguageSelector label="Email language" v-model="form.language"></LanguageSelector>
        </v-card-text>
        <v-card-actions>
          <v-btn
            type="submit"
            :loading="saving"
            color="success"
            @click="submit"
            :disabled="!valid"
          >Send</v-btn>
          <v-btn :disabled="saving">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/rules"
import LanguageSelector from "@/components/LanguageSelector"

export default {
  mixins: [rules],
  components: {
    LanguageSelector
  },
  data() {
    return {
      saving: false,
      dialog: false,
      valid: false,
      serverId: "",
      customerId: "",
      form: {
        language: "en",
        firstName: "",
        middleName: "",
        lastName: "",
        email: ""
      }
    }
  },
  methods: {
    open(customer) {
      this.serverId = customer.serverID
      this.customerId = customer.id
      this.form = {
        language: "en",
        firstName: "",
        middleName: "",
        lastName: "",
        email: ""
      }
      this.dialog = true
    },
    async submit() {
      if (this.$refs.form.validate()) {
        this.saving = true

        await this.$store.dispatch("resendEmail", {
          serverId: this.serverId,
          customerId: this.customerId,
          firstName: this.form.firstName,
          middleName: this.form.middleName,
          lastName: this.form.lastName,
          email: this.form.email,
          language: this.form.language
        })

        this.$store.dispatch("notify", {
          message: "Email has been resent",
          color: "success"
        })

        this.saving = false
        this.dialog = false
      }
    }
  }
}
</script>
