export default {
  data: () => ({
    preventLeave: false
  }),
  computed: {
    buttonAppend() {
      if (this.preventLeave) return '*'
      else return ''
    }
  },
  watch: {
    form: {
      handler() {
        if (!this.loading) this.preventLeave = true
      },
      deep: true
    }
  },
  async beforeRouteLeave(to, from, next) {
    if (this.preventLeave) {
      this.preventLeave = false
      next(false)

      let answer = await this.$root.$confirmLeave({
        to: to.path
      })

      if (answer === 'submit') {
        this.redirectTo = to.name
        this.submit()
      }

      if (answer === 'cancel') {
        this.preventLeave = true
      }
    } else next()
  }
}
