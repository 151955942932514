import graphql from '@/imports/graphql'

export default {
  actions: {
    async saveDocument(context, params) {
      let res = await graphql(`
        mutation SaveDocument($reportId: String, $forAdmin: Boolean!, $forFinancial: Boolean!, $location: ObjectId!, $forStandard: Boolean!) {
          core_addtemplatedocument(
            reportID: $reportId,
            forAdmin: $forAdmin,
            forStandard: $forStandard,
            forFinancial: $forFinancial,
            locationId: $location,
            token: "${context.rootState.token}"
          ) {
            result {
              id
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.core_addtemplatedocument.result
    },
    async getDocuments(context, params) {
      let res = await graphql(`
        query GetDocuments {
          core_listtemplatedocuments(
            token: "${context.rootState.token}"
          ) {
            result {
              createdDateTime
              forAdminUser
              forFinancialUser
              id
              serverID
              locationId
              reportId: reportID
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.core_listtemplatedocuments.result
    },
    async deployDocument(context, params) {
      let res = await graphql(`
        mutation DeployDocument($serverId: Int!, $docId: ObjectId!) {
          core_deployapprovaldocument(
            serverid: $serverId,
            docId: $docId,
            token: "${context.rootState.token}"
          ) {
            result
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.core_deployapprovaldocument.result
    }
  }
}
