import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":"500"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c(VCard,[_c(VCardTitle,[_c('div',{staticClass:"title"},[_vm._v("Deploying document "+_vm._s(_vm.doc.documentName))])]),_c(VCardText,[_c(VList,_vm._l((_vm.servers),function(server){return _c(VListItem,{key:server.id},[_c(VListItemAction,[(server.done)?_c(VIcon,{attrs:{"color":"success"}},[_vm._v("check")]):(server.error)?_c(VIcon,{attrs:{"color":"error"}},[_vm._v("error_outline")]):_c(VProgressCircular,{attrs:{"width":3,"size":20,"indeterminate":"","color":"grey"}})],1),_c(VListItemContent,[_c(VListItemTitle,{domProps:{"textContent":_vm._s(server.name)}})],1)],1)}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }