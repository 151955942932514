import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('AssignPackage',{ref:"assignPackage"}),_c(VBtn,{attrs:{"color":"primary","to":"/packages/create"}},[_vm._v("Create package")]),_c(VDialog,{attrs:{"width":"500"},model:{value:(_vm.printing),callback:function ($$v) {_vm.printing=$$v},expression:"printing"}},[_c(VCard,[_c(VCardTitle,[_c('div',{staticClass:"title"},[_vm._v("Printing label")])]),_c(VCardText,[_c(VProgressLinear,{attrs:{"indeterminate":""}})],1)],1)],1),_c(VTextField,{staticClass:"mb-3",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.packages,"expanded":_vm.expanded,"search":_vm.search,"single-expand":"","item-key":"id"},on:{"update":_vm.pagination,"click:row":_vm.clickedTableRow},scopedSlots:_vm._u([{key:"items",fn:function(props){return [_c('tr',{on:{"click":function($event){props.expanded = !props.expanded}}},[_c('td',[_vm._v(_vm._s(props.item.id))]),_c('td',[_vm._v(_vm._s(props.item.customerId))]),_c('td',[_vm._v(_vm._s(props.item.locationId))]),_c('td',[_vm._v(_vm._s(props.item.createdDateTime))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.count))]),_c('td',{staticClass:"text-xs-right"},[_vm._v(_vm._s(props.item.nominalCount))])])]}},{key:"expanded-item",fn:function(props){return [_c('div',{staticClass:"d-flex flex-row pa-2"},[_c(VBtn,{staticClass:"mx-1 px-4",attrs:{"color":"warning","to":{name: 'PackageEdit', params: {id: props.item.id}}}},[_vm._v("Edit")]),_c(VBtn,{staticClass:"mx-1 px-4",attrs:{"disabled":props.item.count !== props.item.nominalCount,"color":"success"},on:{"click":function($event){return _vm.printLabel(props.item)}}},[_vm._v("Print label")])],1)]}}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }