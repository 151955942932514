<template>
  <div>
    <v-dialog v-model="dialog" width="500" persistent>
      <v-form v-model="valid" ref="form" @submit.prevent="login">
        <v-card>
          <v-card-title>
            <v-icon class="mr-2" color="warning">nl-icon</v-icon>
            <div
              class="title text-uppercase font-weight-light"
              style="font-size: 15px !important; padding-top: 3px"
            >
              Superadmin login (Legacy)
            </div>
          </v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-text-field :rules="[rules.required]" v-model="user" label="User *"></v-text-field>
            <v-text-field
              :rules="[rules.required]"
              type="password"
              v-model="password"
              label="Password *"
            ></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="loading" :disabled="!valid" type="submit" color="primary">Login</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <div style="position: fixed; right: 10px; top: 10px">
      <p class="caption pa-0" style="text-align: right">
        <span class="grey--text">Environment:</span>
        <span class="grey--text" v-text="appEnv"></span>
        <br />
        <span class="grey--text">Version:</span>
        <span class="grey--text" v-text="appVersion"></span>
        <br />
        <span class="grey--text">Revision:</span>
        <span class="grey--text" v-text="appRevision"></span>
      </p>
    </div>
  </div>
</template>

<script>
import rules from '@/mixins/rules'

export default {
  mixins: [rules],
  data() {
    return {
      valid: false,
      loading: false,
      dialog: true,
      user: '',
      password: '',
      remember: false,
    }
  },
  methods: {
    async login() {
      if (this.$refs.form.validate()) {
        this.loading = true

        try {
          let res = await this.$store.dispatch('prelogin', {
            user: this.user,
            password: this.password,
            remember: this.remember,
          })

          if (!res) {
            this.$refs.form.reset()
            this.$store.dispatch('notify', {
              message: 'Error logging in',
              color: 'error',
            })
          }
        } catch (e) {
          this.$refs.form.reset()
          this.$store.dispatch('notify', {
            message: 'Error logging in',
            color: 'error',
          })
        }

        this.loading = false
      }
    },
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_BUILDVERSION + '.' + process.env.VUE_APP_BUILDNUMBER
    },
    appRevision() {
      return process.env.VUE_APP_BUILDREVISIONSHORT
    },
    appEnv() {
      return process.env.NODE_ENV
    },
  },
}
</script>
