<template>
  <div style="max-width: 500px; margin: 0 auto;">
    <v-breadcrumbs>
      <v-breadcrumbs-item exact :to="{name: 'Documents'}">Documents</v-breadcrumbs-item>
      <v-breadcrumbs-item>
        <template v-if="id">Edit document</template>
        <template v-else>Create document</template>
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="!loading">
      <v-form @submit.prevent="submit" v-model="valid" ref="form">
        <v-card-title>
          <div class="headline">
            <template v-if="id">Edit document</template>
            <template v-else>Create document</template>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="form.reportId" :rules="[rules.required]" label="Report ID *"></v-text-field>
          <LocationPicker v-model="form.location" :rules="[rules.required]" label="Location *"></LocationPicker>
          <v-checkbox label="Enabled for admins" hide-details v-model="form.forAdmin"></v-checkbox>
          <v-checkbox label="Enabled for financial users" hide-details v-model="form.forFinancial"></v-checkbox>
          <v-checkbox label="Enabled for standard users" hide-details v-model="form.forStandard"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="saving"
            type="submit"
            color="success"
            :disabled="!valid"
          >Save {{buttonAppend}}</v-btn>
          <v-btn :disabled="saving" :to="{name: 'Documents'}" exact color="info">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-progress-linear v-else indeterminate></v-progress-linear>
  </div>
</template>

<script>
import rules from "@/mixins/rules"
import preventLeave from "@/mixins/preventLeave"
import LocationPicker from "@/components/common/LocationPicker"

export default {
  mixins: [rules, preventLeave],
  components: {
    LocationPicker
  },
  data() {
    return {
      loading: true,
      valid: false,
      id: null,
      saving: false,
      version: 0,
      form: {
        reportId: "",
        forAdmin: false,
        forFinancial: false,
        forStandard: false,
        location: ""
      }
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.saving = true
        this.preventLeave = false
        await this.$store.dispatch("saveDocument", {
          ...this.form
        })
        this.saving = false
        this.$router.push({ name: "Documents" })
      }
    }
  },
  async mounted() {
    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>
