import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VList,[_c(VListItem,{attrs:{"router":"","to":"/"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("business")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Customers")])],1)],1),_c(VListItem,{attrs:{"router":"","to":"/batches"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("all_inbox")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Batches")])],1)],1),_c(VListItem,{attrs:{"router":"","to":"/packages"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("archive")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Packages")])],1)],1),_c(VListItem,{attrs:{"router":"","to":"/users"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("people")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Users")])],1)],1),_c(VListItem,{attrs:{"router":"","to":"/locations"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("location_city")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Locations")])],1)],1),_c(VListItem,{attrs:{"router":"","to":"/documents"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("insert_drive_file")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Documents")])],1)],1),_c(VListItem,{attrs:{"router":"","to":"/gates"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("nl-gpsgate")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Gates")])],1)],1),_c(VListItem,{attrs:{"router":"","to":"/search"}},[_c(VListItemAction,[_c(VIcon,[_vm._v("search")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Search")])],1)],1),_c(VListItem,{on:{"click":function($event){return _vm.$store.commit('logout')}}},[_c(VListItemAction,[_c(VIcon,[_vm._v("exit_to_app")])],1),_c(VListItemContent,[_c(VListItemTitle,[_vm._v("Log out")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }