<template>
  <div>
    <PackageScanner ref="packageScanner"></PackageScanner>
    <GateScanner ref="gateScanner"></GateScanner>
    <ResendEmail ref="resendEmail"></ResendEmail>

    <v-btn color="primary" to="/customers/create">Create customer</v-btn>

    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="customers"
      class="elevation-1"
      :expanded="expanded"
      :search="search"
      single-expand
      item-key="companyName"
      :loading="loading"
      @update="pagination"
      @click:row="clickedTableRow"
    >
      <!-- <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.companyName }}</td>
          <td class="text-xs-right">{{ props.item.id }}</td>
          <td class="text-xs-right">{{ props.item.location ? props.item.location.name : '' }}</td>
          <td class="text-xs-right">{{ props.item.serverName }}</td>
        </tr>
      </template>-->

      <template v-slot:expanded-item="props">
        <div class="d-flex flex-row pa-2">
          <v-btn
            class="mx-1 px-4"
            color="warning"
            :to="{name: 'CustomerEdit', params: {id: props.item.id, serverId: props.item.serverID}}"
          >Edit</v-btn>
          <v-btn class="mx-1 px-4" color="primary" @click="login(props.item)">Login</v-btn>
          <v-btn class="mx-1 px-4" color="success" @click="addPackages(props.item)">Add packages</v-btn>
          <v-btn class="mx-1 px-4" color="success" @click="addPackagesAuto(props.item)">A.P.A</v-btn>
          <v-btn class="mx-1 px-4" color="success" @click="addGate(props.item)">Add gate</v-btn>
          <v-btn
            v-if="!props.item.hasFirstUser"
            color="success"
            @click="resendEmail(props.item)"
          >Resend email</v-btn>
        </div>
      </template>
    </v-data-table>
    <span
      class="grey--text ; font-size: x-small; float: right;"
    >*A.P.A: Add packadge and assign to autocreated dummy assets (SGRE Feature)</span>
  </div>
</template>

<script>
import PackageScanner from '@/components/common/PackageScanner'
import GateScanner from '@/components/customers/GateScanner'
import ResendEmail from '@/components/ResendEmail'
import clickedTableRow from '@/mixins/clickedTableRow'
import record from '@/mixins/record'

export default {
  components: {
    GateScanner,
    PackageScanner,
    ResendEmail
  },
  mixins: [clickedTableRow, record],
  data() {
    return {
      expanded: [],
      search: '',
      loading: true,
      headers: [
        { text: 'Company', value: 'companyName' },
        { text: 'ID', value: 'id', align: 'right' },
        // { text: 'Server ID', value: 'serverID', align: 'right' },
        { text: 'Location', value: 'customer.location.name', align: 'right' },
        { text: 'Server name', value: 'serverName', align: 'right' }
        // { text: 'Server URL', value: 'serverUrl', align: 'right' }
      ],
      customers: [],
      pagination: {
        sortBy: 'companyName',
        rowsPerPage: 25
      }
    }
  },
  computed: {
    frontendUrl() {
      // eslint-disable-next-line no-undef
      return process.env.VUE_APP_FRONTEND_URL
    }
  },
  mounted() {
    this.getCustomers()
  },
  methods: {
    async getCustomers() {
      this.loading = true
      this.customers = await this.$store.dispatch('getCustomers')
      this.loading = false
    },
    async login(customer) {
      let res = await this.$store.dispatch('loginCustomer', {
        customerId: customer.id,
        serverId: customer.serverID
      })

      window.open(
        // eslint-disable-next-line no-undef
        process.env.VUE_APP_FRONTEND_URL +
          'adminlogin/' +
          res +
          '/' +
          encodeURIComponent(customer.serverUrl) +
          '/' +
          encodeURIComponent(customer.companyName) +
          '/Dashboard'
      )
    },
    async addGate(customer) {
      this.$store.state.currentCustomer = customer

      let gateScanned = await this.$refs.gateScanner.scan()

      if (gateScanned) {
        await this.$store.dispatch('isGateConnected').then(res => {
          if (res.result) {
            this.$store.dispatch('notify', {
              message: 'Gate is connected, wait for initialization...',
              color: 'primary'
            })
            this.initGate(
              gateScanned.vId,
              gateScanned.deviceName,
              gateScanned.throttleInterval,
              gateScanned.customerId,
              gateScanned.serverId
            )
          } else {
            this.$store.dispatch('notify', {
              message: 'Gate is not connected',
              color: 'error'
            })
          }
        })
      }
    },
    async initGate(vId, deviceName, throttleInterval, customerId, serverId) {
      await this.$store
        .dispatch('initGate', {
          vid: vId,
          name: deviceName,
          throttleInterval: throttleInterval,
          customerId: customerId,
          serverid: serverId
        })
        .then(res => {
          if (res.log) {
            // console.log(res.log)
            this.$store.dispatch('notify', {
              message: 'Gate was initiated',
              color: 'primary'
            })
          } else {
            this.$store.dispatch('notify', {
              message: this.errorStatus(res),
              color: 'error'
            })
          }
          this.$refs.gateScanner.close()
        })
    },
    async addPackages(customer) {
      let res = await this.$refs.packageScanner.scan()
      if (res) {
        try {
          for (const p of res) {
            await this.$store.dispatch('assignPackage', {
              packageId: p.id,
              customerId: customer.id,
              version: p.version,
              serverId: customer.serverID,
              autoGenerateAssets: false
            })
          }
          this.$store.dispatch('notify', {
            message: 'Packages assigned',
            color: 'success'
          })
        } catch (e) {
          this.$store.dispatch('notify', {
            message: 'Error assigning package',
            color: 'error'
          })
        }
        this.$refs.packageScanner.close()
      }
    },
    async addPackagesAuto(customer) {
      let res = await this.$refs.packageScanner.scan()
      if (res) {
        try {
          for (const p of res) {
            await this.$store.dispatch('assignPackage', {
              packageId: p.id,
              customerId: customer.id,
              version: p.version,
              serverId: customer.serverID,
              autoGenerateAssets: true
            })
          }
          this.$store.dispatch('notify', {
            message: 'Packages assigned',
            color: 'success'
          })
        } catch (e) {
          this.$store.dispatch('notify', {
            message: 'Error assigning package',
            color: 'error'
          })
        }
        this.$refs.packageScanner.close()
      }
    },
    resendEmail(customer) {
      this.$refs.resendEmail.open(customer)
    }
  }
}
</script>
