import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"max-width":_vm.options.width},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"]))return null;return _vm.cancel()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline",attrs:{"primary-title":""}},[_vm._v(_vm._s(_vm.title))]),(!!_vm.message)?_c(VCardText,{domProps:{"innerHTML":_vm._s(_vm.message)}}):_vm._e(),_c(VDivider),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"color":"error"},nativeOn:{"click":function($event){return _vm.agree()}}},[_vm._v("Delete")]),_c(VBtn,{nativeOn:{"click":function($event){return _vm.cancel()}}},[_vm._v("Cancel")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }