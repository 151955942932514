import graphql from '@/imports/graphql'

export default {
  actions: {
    async getCustomers(context) {
      let res = await graphql(`
        query GetCustomers($token: String!) {
          core_customerlist(
            token: $token,
            limit: 1000,
            page: 1
          ) {
            result {
              companyName
              id
              serverID
              serverName
              serverUrl
              locationId
              hasFirstUser
              location {
                name
              }
            }
            errors {
              message
            }
          }
        }
      `, {
        token: context.rootState.token
      })
      return res.data.core_customerlist.result
    },
    async saveCustomer(context, params) {
      let res = await graphql(`
        mutation SaveCustomer(
          $locationId: ObjectId!,
          $id: ObjectId,
          $serverId: Int!,
          $companyName: String!,
          $vAT: String,
          $firstUserFirstName: String,
          $firstUserMiddleName: String,
          $firstUserLastName: String,
          $welcomeEmailAddress: String,
          $welcomeEmailLanguage: String,
          $language: String
        ) {
          core_customersave(
            update: {
              id: $id,
              companyName: $companyName,
              vAT: $vAT,
              locationId: $locationId,
              language: $language,
              firstUserFirstName: $firstUserFirstName,
              firstUserMiddleName: $firstUserMiddleName,
              firstUserLastName: $firstUserLastName,
              welcomeEmailAddress: $welcomeEmailAddress,
              welcomeEmailLanguage: $welcomeEmailLanguage
            },
            serverid: $serverId,
            token: "${context.rootState.token}"
          ) {
            result {
              id
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res
    },
    async deleteCustomer(context, params) {
      let res = await graphql(`
        mutation DeleteCustomer($customerId: ObjectId!, $serverId: Int!) {
          core_customerdelete(
            customerId: $customerId,
            serverid: $serverId,
            token: "${context.rootState.token}"
          ) {
            result
            errors {
              message
            }
          }
        }
      `, params)
      return res
    },
    async getCustomer(context, params) {
      let res = await graphql(`
        query GetCustomer($id: ObjectId!, $serverId: Int!) {
          core_customerget(
            id: $id,
            token: "${context.rootState.token}",
            serverid: $serverId
          ) {
            result {
              companyName
              planId
              vAT
              detectorSMSCount
              detectorSMSPriceTotal
              id
              serverID
              locationId
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.core_customerget.result
    },
    async loginCustomer(context, params) {
      let res = await graphql(`
        query LoginCustomer ($customerId: ObjectId!, $serverId: Int!) {
          core_login(
            customer: $customerId,
            serverid: $serverId,
            token: "${context.rootState.token}"
          ) {
            result
          }
        }
      `, params)
      return res.data.core_login.result
    },
    async resendEmail(context, params) {
      let res = await graphql(`
        mutation ResendEmail ($customerId: ObjectId!, $serverId: Int!, $firstName: String, $middleName: String, $lastName: String, $email: String, $language: String) {
          core_resendwelcome(
            customerId: $customerId,
            serverid: $serverId,
            firstUserFirstName: $firstName,
            firstUserMiddleName: $middleName,
            firstUserLastName: $lastName,
            welcomeEmailAddress: $email,
            welcomeEmailLanguage: $language,
            token: "${context.rootState.token}"
          ) {
            result
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.core_resendwelcome.result
    }
  }
}
