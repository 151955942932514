<template>
  <div>
    <v-form @submit.prevent="submit" v-model="valid" ref="form">
      <v-row>
        <v-col cols="2">
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Search"
            single-line
            hide-details
            :rules="[rules.required]"
          ></v-text-field>
        </v-col>

        <v-col cols="2">
          <v-select
            v-model="selectedServer"
            :items="serverList"
            item-text="name"
            label="Server"
            persistent-hint
            return-object
            single-line
            :rules="[rules.required]"
          ></v-select>
        </v-col>
        <v-col class="align-self-center" cols="1">
          <v-btn :loading="loading" type="submit" color="primary" :disabled="!valid">Search</v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-data-table
      :loading="this.loading"
      loading-text="Searching... Please wait"
      :headers="headers"
      :items="data"
      class="elevation-1"
      @update="pagination"
      item-key="id"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.customerId }}</td>
          <td>{{ props.item.brand }}</td>
          <td>{{ props.item.model }}</td>
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.serial}}</td>
        </tr>
      </template>

      <template slot="expand">
        <v-card class="pa-2">No actions</v-card>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import rules from '@/mixins/rules'
export default {
  mixins: [rules],
  props: {
    serverList: { type: Array }
  },
  data() {
    return {
      loading: false,
      valid: false,
      selectedServer: null,
      search: '',
      data: [],
      headers: [
        { text: 'Customer id', value: 'customerId' },
        { text: 'Brand', value: 'brand' },
        { text: 'Model', value: 'model' },
        { text: 'Id', value: 'id' },
        { text: 'Serial', value: 'serial' }
      ],
      pagination: {
        sortBy: 'customerId',
        rowsPerPage: 25
      }
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.loading = true
        this.data = await this.$store.dispatch('findAssets', {
          serverId: this.selectedServer.id,
          search: this.search
        })
        this.loading = false
      }
    }
  }
}
</script>