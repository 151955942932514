<template>
  <v-container>
    <v-flex md12></v-flex>
    <v-flex md12>
      <div v-if="state==='picking'">
        <div class="headline">Select camera</div>
				<div class="py-4">
					<select class="camera-select py-1"></select>
				</div>
				<v-btn color="primary" @click="initSelectedCamera()">play</v-btn>
      </div>
      <div  v-else-if="state === 'scanning'">
        <div class="d-flex flex-row">
          <div class="video-container">
            <canvas width="320" height="240" id="webcodecam-canvas"></canvas>
            <svg v-show="scanLine">
              <line x1="0" y1="0" x2="300" y2="0" style="stroke:rgb(255,0,0);stroke-width:5" />Sorry, your browser does not support inline SVG.
            </svg>
          </div>
          <div class="px-4">
            <h3>Status:</h3>
            <p id="barcode" ref="barcode" v-text="scanningStatus"></p>
            <v-btn v-if="!isScanned" @click="acceptBarcode">accept</v-btn>
            <v-btn v-else @click="scan">Scan</v-btn>
          </div>
        </div>
        <div ref="settings" v-show="!isScanned">
          <div class="d-flex flex-row pt-4">
            <label>Camera settings:</label>
            <v-checkbox class="mt-0 pt-0 px-2" v-model="cameraSettings" type="checkbox"></v-checkbox>
          </div>

          <div v-show="cameraSettings">
            <!-- <div class="pb-4">
               <select class="camera-select" @change="onCameraChange()" ></select> 
            </div> -->

            <label width="100">Zoom: {{zoomLabel}}</label>
            <v-slider
              v-model="zoom"
              append-icon="zoom_in"
              prepend-icon="zoom_out"
              min="10"
              max="30"
            ></v-slider>

            <label width="100">Brightness: {{brightness}}</label>
            <v-slider v-model="brightness" min="0" max="128" value="0">
              <v-icon slot="prepend">mdi-minus</v-icon>
              <v-icon slot="append">mdi-plus</v-icon>
            </v-slider>

            <label width="100">Contrast: {{contrast}}</label>
            <v-slider v-model="contrast" min="0" max="64" value="0">
              <v-icon slot="prepend">mdi-minus</v-icon>
              <v-icon slot="append">mdi-plus</v-icon>
            </v-slider>
            <v-row no-gutters>
              <v-col>
                <label class="float-left mr-2">Sharpness:</label>
                <v-checkbox class="ma-0 pa-0" v-model="sharpness" type="checkbox"></v-checkbox>
              </v-col>
              <v-col>
                <label class="float-left mr-2">Grayscale:</label>
                <v-checkbox class="ma-0 pa-0" v-model="grayscale" type="checkbox"></v-checkbox>
              </v-col>
            </v-row>
          </div>
        </div>
      </div>
    </v-flex>
  </v-container>
</template>
<script> 
import WebCodeCamJS from '@/imports/webcodecam'
import record from '@/mixins/record'

export default {
	mixins: [record],
	data() {
		return {
			state: 'picking',
			txt: '',
			decoder: '',
			scanningStatus: '',
			zoom: '',
			brightness: '',
			contrast: '',
			sharpness: false,
			grayscale: false,
			zoomLabel: '',
			barcodeField: '',
			cameraSettings: false,
			isScanned: false,
			scanLine: false,
			cameras: []
		}
	},
	watch: {
		zoom(zoom) {
			// if(this.decoder !== null) return
			 if (this.decoder.isInitialized()) {
				let value = typeof zoom !== 'undefined' ? zoom / 10 : null
				this.decoder.options.zoom = value
				this.zoomLabel = value
			 }
		},
		brightness(brightness) {
			if (this.decoder.isInitialized()) {
				this.decoder.options.brightness = brightness
			}
		},
		contrast(contrast) {
			if (this.decoder.isInitialized()) {
				this.decoder.options.contrast = contrast
			}
		},
		sharpness(sharpness) {
			if (this.decoder.isInitialized()) {
				if (sharpness) {
					this.decoder.options.sharpness = [0, -1, 0, -1, 5, -1, 0, -1, 0]
				} else {
					this.decoder.options.sharpness = []
				}
			}
		},
		grayscale(grayscale) {
			if (this.decoder.isInitialized()) {
				if (grayscale) {
					this.decoder.options.grayScale = true
				} else {
					this.decoder.options.grayScale = false
				}
			}
		}
	},
	mounted() {
		this.decoder = new WebCodeCamJS('#webcodecam-canvas').buildSelectMenu('.camera-select', 'environment|back') 
 
		const getZomm = setInterval(function () {
			let a
			try {
				a = this.decoder.getOptimalZoom()
			} catch (e) {
				a = 0
			}
			if (!!a && a !== 0) {
				this.zoom(a)
				clearInterval(getZomm)
			}
		}, 500)
 
	},
	methods: {
		async initSelectedCamera(){
			this.state = 'scanning'
			this.$nextTick(() => {
			 this.decoder.init(this.initObj)
			  this.play()
			  }			
			)
		},
		onCameraChange(){
			if (this.decoder.isInitialized()) {
				this.decoder.stop().play()
			}
		},
		showScanLine() {
			setTimeout(() => {
				this.scanLine = true
			}, 1500)
		},
		scan() {
			this.isScanned = false
			this.play()
		},
		play() {
			if (!this.decoder.isInitialized()) {
				this.scanningStatus = 'Scanning ...'
			} else {
				this.scanningStatus = 'Scanning ...'
				this.decoder.play()
				this.showScanLine()
			}
		},
		pause() {
			this.scanningStatus = 'Paused'
			this.decoder.pause()
		},
		stop() {
			this.scanningStatus = 'Stopped'
			this.decoder.stop()
			this.scanLine = false
		},
		acceptBarcode() {
			if (this.decoder.getResult()) {
				if (!this.validateBarcode(this.decoder.getResult())) return this.scanningStatus = 'Barcode was scanned incorrectly! Try one more time'
				this.$emit('scannedcode', this.decoder.getResult())
				this.stop()
				this.isScanned = true
			} else {
				this.scanningStatus = 'Scan first!!!'
			}
		},
		validateBarcode(code) {
			code.split('')
			if (code.length === 19) return true
			return false
		},
		initObj(){
			const args = {
				autoBrightnessValue: 100,
				resultFunction(res) {
					document.querySelector('#barcode').innerText = res.code
				},
				getDevicesError(error) {
					var p, message = 'Error detected with the following parameters:\n'
					for (p in error) {
						message += p + ': ' + error[p] + '\n'
					}
					alert(message)
				},
				getUserMediaError(error) {
					var p, message = 'Error detected with the following parameters:\n'
					for (p in error) {
						message += p + ': ' + error[p] + '\n'
					}
					alert(message)
				},
				cameraError(error) {
					var p, message = 'Error detected with the following parameters:\n'
					if (error.name == 'NotSupportedError') {
						var ans = confirm('Your browser does not support getUserMedia via HTTP!\n(see: https:goo.gl/Y0ZkNV).\n You want to see github demo page in a new window?')
						if (ans) {
							window.open('https://andrastoth.github.io/webcodecamjs/')
						}
					} else {
						for (p in error) {
							message += p + ': ' + error[p] + '\n'
						}
						alert(message)
					}
				}
			}      
			return args
		}
	},
	destroyed() {
		if(this.decoder !== null && this.decoder !== undefined) this.decoder.stop()
	}
}
</script>

<style scoped>
 select {      
	height: 35px;
	padding: 5px 35px 5px 5px;
	font-size: 18px;
	border: 2px solid #ccc;
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: url("/uploads/media/default/0001/02/f7b4d3d2ba3fe1d8518e6e63d7740e1e73921abf.png") 96% / 15% no-repeat #eee;
	}
	select::-ms-expand { 
	display: none; /* remove default arrow in IE 10 and 11 */
}
.video-container {
  position: relative;
  width: 320px;
}
.video-container > svg {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 2px;
}
</style>