import Vue from "vue";
import Router from "vue-router";
import Customers from "@/components/customers/Customers";
import Customer from "@/components/customers/Customer";
import AddNanolinks from "@/components/AddNanolinks";
import Batches from "@/components/Batches";
import Batch from "@/components/Batch";
import Packages from "@/components/packages/Packages";
import Package from "@/components/packages/Package";
import Users from "@/components/Users";
import User from "@/components/User";
import Locations from "@/components/locations/Locations";
import Location from "@/components/locations/Location";
import Documents from "@/components/Documents";
import Document from "@/components/Document";
import Search from "@/components/search/Search";
import Gates from "@/components/gates/Gates";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.NODE_ENV === "staging" ? "/adminpanel" : "/",
  routes: [
    {
      path: "/",
      name: "Customers",
      component: Customers
    },
    {
      path: "/customers/create",
      name: "CustomerCreate",
      component: Customer
    },
    {
      path: "/customers/:id/:serverId/edit",
      name: "CustomerEdit",
      component: Customer
    },
    {
      path: "/customers/:id/addnanolinks",
      name: "AddNanolinks",
      component: AddNanolinks
    },
    {
      path: "/batches",
      name: "Batches",
      component: Batches
    },
    {
      path: "/batches/create",
      name: "BatchCreate",
      component: Batch
    },
    {
      path: "/batches/:id/edit",
      name: "BatchEdit",
      component: Batch
    },
    {
      path: "/packages",
      name: "Packages",
      component: Packages
    },
    {
      path: "/packages/create",
      name: "PackageCreate",
      component: Package
    },
    {
      path: "/packages/:id/edit",
      name: "PackageEdit",
      component: Package
    },
    {
      path: "/users",
      name: "Users",
      component: Users
    },
    {
      path: "/users/create",
      name: "UserCreate",
      component: User
    },
    {
      path: "/users/:id/edit",
      name: "UserEdit",
      component: User
    },
    {
      path: "/locations",
      name: "Locations",
      component: Locations
    },
    {
      path: "/locations/create",
      name: "LocationCreate",
      component: Location
    },
    {
      path: "/locations/:id/edit",
      name: "LocationEdit",
      component: Location
    },
    {
      path: "/documents",
      name: "Documents",
      component: Documents
    },
    {
      path: "/documents/create",
      name: "DocumentCreate",
      component: Document
    },
    {
      path: "/gates",
      name: "Gates",
      component: Gates
    },
    {
      path: "/search",
      name: "Search",
      component: Search
    }
  ]
});
