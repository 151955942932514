<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-card-title>
          <div class="title">Add trackers</div>
        </v-card-title>
        <v-card-text>
          <v-form ref="form" v-model="valid">
            <v-input ref="trackersInput" :value="trackers" :rules="[rules.requiredMultiple]">
              <v-flex>
                <FilePicker
                  label="Select file with trackers *"
                  v-on:formData="parseForm"
                  accept=".csv"
                ></FilePicker>
              </v-flex>
            </v-input>
          </v-form>

          <v-list two-line subheader>
            <v-subheader>{{trackers.length}} tracker(s) in file</v-subheader>
            <template v-for="(tracker, index) in trackers">
              <v-list-item :key="tracker.pId">
                <v-list-item-content>
                  <v-list-item-title>
                    <b>VID</b>
                    : {{ tracker.vID }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    <b>PID</b>
                    : {{ tracker.pID }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider v-if="index + 1 < trackers.length" :key="index"></v-divider>
            </template>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" :loading="loading" :disabled="!valid" @click="addFile">Add file</v-btn>
          <v-btn color="warning" @click="dialog = nanolinksfalse">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="primary" @click="dialog = true">Upload file</v-btn>

    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="trackersList"
      class="elevation-1"
      @update="pagination"
      :search="search"
      item-key="id"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.vID }}</td>
          <td>{{ props.item.pID }}</td>
        </tr>
      </template>

      <template slot="expand">
        <v-card class="pa-2">No actions</v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import FilePicker from '@/components/FilePicker'
import rules from '@/mixins/rules'
import Papa from 'papaparse'

export default {
  mixins: [rules],
  components: {
    FilePicker
  },
  data() {
    return {
      dialog: false,
      valid: false,
      loading: false,
      search: '',
      headers: [
        { text: 'Customer ID', value: 'customerId' },
        { text: 'ID', value: 'id' },
        { text: 'VID', value: 'vID' },
        { text: 'PID', value: 'pID' }
      ],
      trackers: [],
      trackersList: [],
      pagination: {
        sortBy: 'id',
        rowsPerPage: 25
      }
    }
  },
  mounted() {
    this.getTrackers()
  },
  methods: {
    async getTrackers() {
      this.trackersList = await this.$store.dispatch('getTrackers', {
        type: 'GPSTRACKER'
      })
    },
    parseForm(form) {
      let file = form.getAll('data')[0]
      let reader = new FileReader()
      reader.addEventListener('load', () => {
        const result = Papa.parse(reader.result, {
          delimiter: ';'
        })
        const trackers = result.data.filter(item => item[0] !== '' && item[0] !== '\n')

        this.trackers = trackers.map(item => {
          return {
            vID: item[0].replace('\n', ''),
            pID: item[1].replace(/\+/g, '-')
          }
        })
      })
      reader.readAsText(file)
    },
    async addFile() {
      this.loading = true
      await this.$store.dispatch('addTrackers', {
        trackers: this.trackers,
        type: 'GPSTRACKER'
      })
      this.loading = false
      this.dialog = false
      this.getTrackers()
    }
  }
}
</script>
