import graphql from '@/imports/graphql'

export default {
  actions: {
    async getServers(context, params) {
      let res = await graphql(`
        query GetServers {
          core_serverlist(
            token: "${context.rootState.token}"
          ) {
            result {
              id
              name
              gateservice
              gateport
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.core_serverlist.result
    }
  }
}
