import axios from 'axios'

export default {
  actions: {
    async getLanguages(context) {

      try {
        // Contact the language server for available languages
        // eslint-disable-next-line no-undef
        let res = await axios.get(process.env.VUE_APP_LANGUAGE_URL + "/" + process.env.VUE_APP_BUILDVERSION + "/languagelist.json")

        let languages = res.data

        // Map the language object from the server to a more template friendly style
        let langAvailable = languages.map((langObj) => {
          let langCode = Object.keys(langObj)[0]

          return {
            lang: langCode,
            friendly: langObj[langCode].languagefriendly,
            friendlyen: langObj[langCode].languagefriendlyen,
            text: langObj[langCode].languagefriendly + ' (' + langObj[langCode].languagefriendlyen + ')'
          }
        })

        return langAvailable
      } catch (e) {
        context.dispatch('notify', {
          message: 'Error contacting language server',
          color: 'error'
        })

        return []
      }
    }
  }
}
