<template>
  <v-dialog v-model="dialog" max-width="500" @keydown.esc="cancel()">
    <v-card>
      <v-card-title class="headline" primary-title>Leave page</v-card-title>
      <v-card-text>You have unsaved changes. What do you want to do?</v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click="leave">Leave</v-btn>
        <v-btn color="info" @click="cancel">Stay</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      message: null,
      title: null,
      options: {
        to: ""
      }
    }
  },
  methods: {
    open(options) {
      this.dialog = true
      this.options = Object.assign(this.options, options)
      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    leave() {
      this.resolve("leave")
      this.dialog = false
      this.$router.push(this.options.to)
    },
    submit() {
      this.dialog = false
      this.resolve("submit")
    },
    cancel() {
      this.resolve("cancel")
      this.dialog = false
    }
  }
}
</script>
