import graphql from '@/imports/graphql'

export default {
  actions: {
    async findUser(context, params) {
      let res = await graphql(
        `
        query FindUser($serverId: Int!, $search: String, $customers: [ObjectId!]) {
          core_finduser(serverid: $serverId, search: $search, customers: $customers, token: "${context.rootState.token}") {
            result {
              id
              email
              customerId
              fullName
              mobileCountryCode
              mobilePhoneNumber
            }
            errors {
              message
            }
          }
        }
      `,
        params
      )
      return res.data.core_finduser.result
    },
    async getUsers(context) {
      let res = await graphql(
        `
          query GetCustomers($token: String!) {
            user_search(token: $token, limit: 1000, page: 1) {
              result {
                fullName
                id
                email
                createdDateTime
                version
                locationId
              }
              errors {
                message
              }
            }
          }
        `,
        {
          token: context.rootState.token
        }
      )
      return res.data.user_search.result
    },
    async saveUser(context, params) {
      let res = await graphql(
        `
        mutation SaveUser($locationId: ObjectId!, $id: ObjectId, $version: Int, $firstName: String, $middleName: String, $lastName: String, $email: String, $passwordCheck: String) {
          user_save(
            token: "${context.rootState.token}",
            update: {
              locationId: $locationId,
              id: $id,
              passwordCheck: $passwordCheck,
              version: $version,
              firstName: $firstName,
              middleName: $middleName,
              lastName: $lastName,
              email: $email
            }
          ) {
            errors {
              message
            }
          }
        }
      `,
        params
      )
      return res.data.user_save.result
    },
    async getUser(context, params) {
      let res = await graphql(
        `
        query GetUser($id: ObjectId!) {
          user_get(id: $id, token: "${context.rootState.token}") {
            errors {
              message
            }
            result {
              email
              firstName
              lastName
              middleName
              id
              version
              locationId
            }
          }
        }
      `,
        params
      )
      return res.data.user_get.result
    }
    // async deleteUser(context, params) {
    //   let res = await graphql(`
    //     mutation DeleteUser($id: ObjectId!, $version: Int!) {
    //       user_delete(id: $id, version: $version, token: "${context.rootState.token}") {
    //         result
    //       }
    //     }
    //   `, params)
    // }
  }
}
