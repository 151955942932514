<template>
  <v-dialog v-model="dialog" width="500" persistent>
    <v-card>
      <v-card-title>
        <div class="title">Scan packages</div>
      </v-card-title>
      <v-card-text>
        <QrScanner @scanned="packageId = $event.qr"></QrScanner>
        <v-list subheader>
          <v-subheader v-if="!packages.length">No packages scanned</v-subheader>
          <v-subheader v-else>{{packages.length}} package(s) scanned</v-subheader>

          <v-list-item v-for="(p, index) in packages" :key="p.id">
            <v-list-item-avatar>
              <v-icon class="grey lighten-1 white--text">archive</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>{{p.id}}</v-list-item-title>
            </v-list-item-content>

            <v-list-item-action @click="removePackage(index)">
              <v-btn icon ripple>
                <v-icon color="error">clear</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-card-text>
      <v-card-actions>
        <v-btn :loading="saving" color="success" @click="done" :disabled="!packages.length">Done</v-btn>
        <v-btn :disabled="saving" @click="cancel">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import QrScanner from "@/components/scanners/QrScanner"
import record from "@/mixins/record.js"
export default {
  mixins: [record],
  components: { QrScanner },
  data() {
    return {
      packageId: "",
      saving: false,
      dialog: false,
      packages: [],
      resolve: null,
      reject: null
    }
  },
  watch: {
    async packageId(val) {
      // If something was actually scanned, and the result is not already in the list of scanned packages
      if (val && !this.packages.find(p => p.id === val)) {

        // document.getElementById("audio").play()

        let p = await this.$store.dispatch("getPackage", {
          id: val
        })

        if (this.customerId(p)) {
          this.$store.dispatch("notify", {
            message: "Package already assigned to customer",
            color: "error"
          })
        } else this.packages.push(p)
      }
    }
  },
  methods: {
    done() {
      this.resolve(this.packages)
      this.saving = true
      //this.close()
    },
    removePackage(index) {
      this.packages.splice(index, 1)
    },
    scan() {
      this.dialog = true

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    close() {
      this.packages = []
      this.dialog = false
    },
    cancel() {
      this.close()
      this.resolve(false)
    }
  }
}
</script>

<style scoped>
#preview {
  width: 100%;
}
</style>
