import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('div',{staticClass:"title"},[_vm._v("Resend welcome email")])]),_c(VForm,{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VCardText,[_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":"First name *"},model:{value:(_vm.form.firstName),callback:function ($$v) {_vm.$set(_vm.form, "firstName", $$v)},expression:"form.firstName"}}),_c(VTextField,{attrs:{"label":"Middle name"},model:{value:(_vm.form.middleName),callback:function ($$v) {_vm.$set(_vm.form, "middleName", $$v)},expression:"form.middleName"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.required],"label":"Last name *"},model:{value:(_vm.form.lastName),callback:function ($$v) {_vm.$set(_vm.form, "lastName", $$v)},expression:"form.lastName"}}),_c(VTextField,{attrs:{"rules":[_vm.rules.required, _vm.rules.email],"label":"Email address *"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}}),_c('LanguageSelector',{attrs:{"label":"Email language"},model:{value:(_vm.form.language),callback:function ($$v) {_vm.$set(_vm.form, "language", $$v)},expression:"form.language"}})],1),_c(VCardActions,[_c(VBtn,{attrs:{"type":"submit","loading":_vm.saving,"color":"success","disabled":!_vm.valid},on:{"click":_vm.submit}},[_vm._v("Send")]),_c(VBtn,{attrs:{"disabled":_vm.saving}},[_vm._v("Cancel")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }