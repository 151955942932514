import axios from 'axios'
import store from '@/store/index'
export default async function (query, variables) {

  try {
    const res = await axios.post(process.env.VUE_APP_SERVER_URL, {
      query,
      variables
    })

    let result
    if (res.data.Errors) result = res.data
    else result = Object.values(res.data.data)[0]
    if (result.Errors && Array.isArray(result.Errors) && result.Errors.length > 0) {
      store.dispatch('notify', {
        message: result.Errors[0].message,
        color: 'error'
      })
    }

    return res.data
  } catch (e) {
    return e
  }
}
