import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VRow,[_c(VCol,{attrs:{"cols":"4","sm":"2"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){_vm.input --}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-minus")])],1)],1),_c(VCol,{attrs:{"cols":"4","sm":"3"}},[_c(VTextField,{directives:[{name:"mask",rawName:"v-mask",value:(_vm.mask),expression:"mask"}],attrs:{"rules":[_vm.rules.throttleInterval],"label":"","clearable":""},model:{value:(_vm.input),callback:function ($$v) {_vm.input=$$v},expression:"input"}})],1),_c(VCol,{attrs:{"cols":"4","sm":"2"}},[_c(VBtn,{staticClass:"mx-2",attrs:{"fab":"","dark":"","small":"","color":"primary"},on:{"click":function($event){_vm.input ++}}},[_c(VIcon,{attrs:{"dark":""}},[_vm._v("mdi-plus")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }