import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VBtn,{attrs:{"color":"primary"},on:{"click":function($event){_vm.dialog = true}}},[_vm._v("Add GPS Gate")]),_c(VTextField,{staticClass:"mb-3",attrs:{"append-icon":"search","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c(VDataTable,{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.trackersList,"search":_vm.search,"item-key":"id"},on:{"update":_vm.pagination}}),_c(VDialog,{attrs:{"max-width":"750"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c('GateScanner',{attrs:{"dialog":_vm.dialog},on:{"closeDialog":function($event){_vm.dialog = false, _vm.getTrackers()}}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }