import graphql from '@/imports/graphql'

export default {
  actions: {
    async findAssets(context, params) {
      let res = await graphql(
        `
         query FindAssets($serverId: Int!, $search: String, $customers: [ObjectId!]) {
          core_findasset(serverid: $serverId, search: $search, customers: $customers, token: "${context.rootState.token}") {
              result {
                id
                model
                brand
                serial
                customerId

              }
            errors {
              message
            }
          }
        }
      `,
        params
      )
      return res.data.core_findasset.result
    }
  }
}
