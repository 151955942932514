import graphql from '@/imports/graphql'

export default {
  actions: {
    async getPackages(context, params) {
      let res = await graphql(`
        query GetPackages {
          package_list(
            limit: 1000,
            page: 1,
            token: "${context.rootState.token}"
          ) {
            result {
              id
              count
              customerId
              createdDateTime
              nominalCount
              version
              locationId
              files {
                batch {
                  batchDescription
                  createdDateTime
                }
              }
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.package_list.result
    },
    async savePackage(context, params) {
      let res = await graphql(`
        mutation SavePackage ($nominalCount: Int!) {
          package_create(
            nominalCount: $nominalCount,
            token: "${context.rootState.token}"
          ) {
            result {
              id
              version
            }
            errors {
              message
            }
          }
        }
      `, params)
      let data = res.data.package_create.result
      return {
        packageId: data.id,
        version: data.version
      }
    },
    async saveFile(context, params) {
      let res = await graphql(`
        mutation SaveFile($packageId: ObjectId!, $batchId: ObjectId!, $version: Int!, $fileData: [PNanolinkInfo]!) {
          package_uploadfile(
            packageId: $packageId,
            batchId: $batchId,
            fileData: $fileData,
            version: $version,
            token: "${context.rootState.token}"
          ) {
            result {
              id
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res
    },
    async assignPackage(context, params) {
      let res = await graphql(`
        mutation AssignPackage($packageId: ObjectId!, $customerId: ObjectId!, $version: Int!, $serverId: Int!, $autoGenerateAssets: Boolean) {
          package_assign(
            packageId: $packageId,
            customerId: $customerId,
            serverid: $serverId,
            version: $version,
            autoGenerateAssets: $autoGenerateAssets,
            token: "${context.rootState.token}"
          ) {
            result {
              id
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res
    },
    async relocatePackage(context, params) {
      let res = await graphql(`
        mutation RelocatePackage($packageId: ObjectId!, $newLocationId: ObjectId!, $version: Int!) {
          package_relocate(
            packageId: $packageId,
            newLocationId: $newLocationId,
            version: $version,
            token: "${context.rootState.token}"
          ) {
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.package_relocate
    },
    async getPackage(context, params) {
      let res = await graphql(`
        query GetPackage($id: ObjectId!) {
          package_get(
            id: $id,
            token: "${context.rootState.token}"
          ) {
            result {
              id
              count
              customerId
              createdDateTime
              nominalCount
              version
              locationId
              files {
                batch {
                  batchDescription
                  creationTime
                }
                manifest {
                  iD
                  iOSCompatible
                  lastContact
                  rSSI
                }
              }
            }
            errors {
              message
            }
          }
        }
      `, params)
      return res.data.package_get.result
    }
    // async deleteBatch (context, params) {
    //   let res = await graphql(`
    //     mutation DeleteBatch($id: String!, $version: Int!) {
    //       batch_delete(
    //         id: $id,
    //         version: $version,
    //         token: "${context.rootState.token}"
    //       ) {
    //         result
    //         errors {
    //           message
    //         }
    //       }
    //     }
    //   `, params)
    //
    //   console.log('deleted batch', res)
    //   return res
    // },
    // async getBatch (context, params) {
    //   let res = await graphql(`
    //     query GetBatch($id: String!) {
    //       batch_get(
    //         id: $id,
    //         token: "${context.rootState.token}"
    //       ) {
    //         result {
    //           id
    //           amount
    //           usedCount
    //           productionDate
    //           productDescription
    //           version
    //           active
    //         }
    //         errors {
    //           message
    //         }
    //       }
    //     }
    //   `, params)
    //
    //   console.log('got batch', res)
    //   return res.data.batch_get.result
    // }
  }
}
