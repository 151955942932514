import graphql from '@/imports/graphql'

export default {
  actions: {
    async getLocations(context) {
      let res = await graphql(`
        query GetLocations {
          location_search(
            limit: 100,
            page: 1,
            token: "${context.rootState.token}"
          ) {
            errors {
              message
            }
            result {
              address1
              address2
              city
              country
              createdDateTime
              headQuarter
              id
              name
              region
              version
              zipCode
            }
          }
        }
      `)
      return res.data.location_search.result
    },
    async saveLocation(context, params) {
      let res = await graphql(`
        mutation SaveLocation($id: ObjectId, $name: String, $address1: String, $address2: String, $address3: String, $zipCode: String, $city: String, $region: String, $country: String, $version: Int) {
          location_save(
            update: {
              id: $id,
              name: $name,
              address1: $address1,
              address2: $address2,
              address3: $address3,
              zipCode: $zipCode,
              city: $city,
              region: $region,
              country: $country,
              version: $version
            },
            token: "${context.rootState.token}"
          ) {
            errors {
              message
            }
            result {
              id
            }
          }
        }
      `, params)
      return res.data.location_save.result
    },
    async getLocation(context, params) {
      let res = await graphql(`
        query GetLocation($id: ObjectId!) {
          location_get(
            id: $id,
            token: "${context.rootState.token}"
          ) {
            errors {
              message
            }
            result {
              address1
              address2
              city
              country
              createdDateTime
              headQuarter
              id
              name
              region
              version
              zipCode
            }
          }
        }
      `, params)
      return res.data.location_get.result
    },
    async deleteLocation(context, params) {
      let res = await graphql(`
        mutation DeleteLocation($id: ObjectId!, $version: Int!) {
          location_delete(
            id: $id,
            version: $version,
            token: "${context.rootState.token}"
          ) {
            errors {
              message
            }
            result
          }
        }
      `, params)
      return res.data.location_delete.result
    }
  }
}
