export default {
  methods: {
    clickedTableRow(value) {
      if (this.expanded.length && this.expanded[0].id == value.id) {
        this.expanded = []
      } else {
        this.expanded = []
        this.expanded.push(value)
      }
    }
  }
}