<template>
  <v-select
    :rules="rules"
    v-model="location"
    :items="locations"
    :label="label"
    item-text="name"
    item-value="id"
  ></v-select>
</template>

<script>
export default {
  props: {
    value: String,
    rules: Array,
    label: {
      type: String,
      default: 'Location'
    }
  },
  data () {
    return {
      location: '',
      locations: []
    }
  },
  async mounted () {
    let locations = await this.$store.dispatch('getLocations')
    this.locations = locations || []
  },
  watch: {
    'value': {
      handler (val) {
        this.location = val
      },
      immediate: true
    },
    location (val) {
      this.$emit('input', val)
    }
  }
}
</script>
