<template>
  <v-dialog v-model="dialog" width="500" :persistent="saving">
    <v-card>
      <v-card-title>
        <div class="title">Assign package to customer</div>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-select
            :disabled="saving"
            item-text="companyName"
            return-object
            v-model="customer"
            item-value="id"
            :items="customers"
            label="Select customer *"
            :rules="[rules.required]"
          ></v-select>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-btn text color="success" :loading="saving" :disabled="!valid" @click="assign">Assign</v-btn>
        <v-btn text color="warning" :disabled="saving" @click="dialog = false">Cancel</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/mixins/rules"

export default {
  mixins: [rules],
  data() {
    return {
      saving: false,
      version: 0,
      packageId: "",
      valid: false,
      dialog: false,
      customers: [],
      customer: ""
    }
  },
  methods: {
    open(packageId, version) {
      this.version = version
      this.packageId = packageId
      this.$refs.form.reset()
      this.dialog = true
    },
    async getCustomers() {
      this.customers = await this.$store.dispatch("getCustomers")
    },
    async assign() {
      this.saving = true
      await this.$store.dispatch("assignPackage", {
        packageId: this.packageId,
        customerId: this.customer.id,
        serverId: this.customer.serverID,
        version: this.version
      })
      this.saving = false
      this.dialog = false
    }
  },
  mounted() {
    this.getCustomers()
  }
}
</script>
