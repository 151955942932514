import Vue from 'vue';
import Vuetify from 'vuetify/lib';

Vue.use(Vuetify);

const vuetifyOptions = {
  theme: {
    themes: {
      light: {
        primary: '#6aa641',
        success: '#6aa641',
        secondary: '#b0bec5',
        accent: '#6aa641',
        error: '#b71c1c'
      }
    },
    options: {
      customProperties: true
    }
  }
}

export default new Vuetify(vuetifyOptions)
