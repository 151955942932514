<template>
  <div>
    <v-breadcrumbs>
      <v-breadcrumbs-item exact :to="{name: 'Customers'}">Customers</v-breadcrumbs-item>
      <v-breadcrumbs-item>Customer name</v-breadcrumbs-item>
      <v-breadcrumbs-item>Add NanoLinks</v-breadcrumbs-item>
    </v-breadcrumbs>

    <FilePicker v-on:formData="parseForm" accept=".json"></FilePicker>

    <v-card v-if="nanolinks">
      <v-list two-line subheader>
        <v-subheader>{{nanolinks.length}} NanoLinks in file</v-subheader>
        <template v-for="(nanolink, index) in nanolinks">
          <v-list-item :key="nanolink.ID">
            <v-list-item-content>
              <v-list-item-title>{{ nanolink.ID }}</v-list-item-title>
              <v-list-item-subtitle>{{ formatDate(nanolink.LastContact) }}</v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-divider v-if="index + 1 < nanolinks.length" :key="index"></v-divider>
        </template>
      </v-list>
    </v-card>
  </div>
</template>

<script>
import FilePicker from "@/components/FilePicker"

export default {
  data() {
    return {
      nanolinks: null
    }
  },
  components: {
    FilePicker
  },
  methods: {
    parseForm(form) {
      let file = form.getAll("data")[0]
      let reader = new FileReader()
      reader.addEventListener("load", () => {
        let parsedFile = reader.result
        this.nanolinks = JSON.parse(parsedFile)
      })
      reader.readAsText(file)
    },
    formatDate(date) {
      if (!date) return false
      let d = new Date(date)
      let options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit"
      }
      return d.toLocaleDateString("da-DK", options)
    }
  }
}
</script>
