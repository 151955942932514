import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c(VCard,[_c(VTabs,{attrs:{"color":"primary","slider-color":"green"}},[_c(VTab,{attrs:{"ripple":""}},[_vm._v("Search users")]),_c(VTab,{attrs:{"ripple":""}},[_vm._v("Search nanolinks")]),_c(VTab,{attrs:{"ripple":""}},[_vm._v("Search assets")]),_c(VTabItem,[_c(VDivider),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('SearchUsers',{attrs:{"server-list":_vm.serverList}})],1)],1)],1),_c(VTabItem,[_c(VDivider),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('SearchNanolinks',{attrs:{"server-list":_vm.serverList}})],1)],1)],1),_c(VTabItem,[_c(VDivider),_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[_c('SearchAssets',{attrs:{"server-list":_vm.serverList}})],1)],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }