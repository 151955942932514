<template>
  <div style="max-width: 700px; margin: 0 auto;" class="pt-4 pb-8">
    <v-breadcrumbs>
      <v-breadcrumbs-item exact :to="{name: 'Gates'}">GPS Gates</v-breadcrumbs-item>
      <v-breadcrumbs-item>Create GPS Gate</v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-stepper v-if="step < 5" v-model="step">
      <v-stepper-header>
        <v-stepper-step step="1">Select model</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="2">Scan QR Code</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="3">Scan IMEI</v-stepper-step>
        <v-divider></v-divider>
        <v-stepper-step step="4">Scan SIM card barcode</v-stepper-step>
      </v-stepper-header>
      <v-stepper-items>
        <v-stepper-content step="1">
          <v-container class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-card>
                  <div id="scanner-container"></div>
                  <v-card class="pa-4">
                    <v-select
                      v-model="gateSelected"
                      :items="gpsGateModels"
                      outlined
                      label="GPS gate model"
                    ></v-select>
                  </v-card>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="2">
          <v-container class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-card>
                  <v-card v-if="step==2">
                    <QrScanner :dialog="dialog" @scanned="nanolinkQrScanned = $event.qr"></QrScanner>
                  </v-card>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="3">
          <v-container class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-card>
                  <v-card v-if="step==3">
                    <QrScanner :dialog="dialog" @scanned="imeiScanned = $event.qr"></QrScanner>
                  </v-card>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>

        <v-stepper-content step="4">
          <v-container class="pa-0">
            <v-layout row wrap>
              <v-flex xs12>
                <v-card>
                  <v-card v-if="step==4">
                    <BarScanner ref="barscanner" @scannedcode="codeAccepted"></BarScanner>
                  </v-card>
                </v-card>
              </v-flex>
            </v-layout>
          </v-container>
        </v-stepper-content>
      </v-stepper-items>
    </v-stepper>
    <v-divider></v-divider>
    <v-form ref="form">
      <v-row class="px-8" align="center">
        <v-col v-show="gateSelected" cols="6">
          <span class="caption mr-2 grey--text">GPS gate model:</span>
          <span v-text="gateSelected"></span>
        </v-col>
        <v-col v-show="imeiScanned" cols="6">
          <span class="caption mr-2 grey--text">IMEI:</span>
          <span v-text="imeiScanned"></span>
        </v-col>
      </v-row>
      <v-row class="px-8" align="center">
        <v-col v-show="nanolinkQrScanned" cols="6">
          <span class="caption mr-2 grey--text">QR code:</span>
          <span v-text="nanolinkQrScanned"></span>
        </v-col>
        <v-col v-show="phoneBarcodeScanned" cols="6">
          <span class="caption mr-2 grey--text">SIM card barcode:</span>
          <span v-text="phoneBarcodeScanned"></span>
        </v-col>
      </v-row>
      <v-row v-show="gateStatus" class="px-8" align="center">
        <v-col v-if="gateStatus.result" cols="12">
          <span class="caption mr-2 grey--text">Status:</span>
          <span class="green--text">GPS gate {{gateSelected}} created!</span>
        </v-col>
        <v-col v-else cols="12">
          <span class="caption mr-2 grey--text">Status:</span>
          <span class="red--text" v-text="errorStatus(gateStatus)"></span>
        </v-col>
      </v-row>
    </v-form>
    <v-divider></v-divider>

    <v-btn
      v-if="step < 5"
      color="primary"
      :disabled="(!gateSelected && step ==1) || (!nanolinkQrScanned && step==2) || (!imeiScanned && step==3) || (!phoneBarcodeScanned && step==4)"
      class="mx-8 my-4"
      @click="step ++"
    >Next</v-btn>
    <v-btn
      v-show="step == 5 && !saved"
      :loading="saving"
      class="mx-8 my-4"
      color="primary"
      @click="createGPSGate()"
    >Create GPS gate</v-btn>
    <v-btn v-show="step == 5 && saved" class="mx-8 my-4" @click="reset">Scan again</v-btn>
    <v-btn
      class="mx-8 my-4"
      v-text="saved ? 'close' : 'cancel'"
      :disabled="saving"
      @click="reset && $emit('closeDialog')"
    ></v-btn>
  </div>
</template>
<script>
import QrScanner from '@/components/scanners/QrScanner'
import BarScanner from '@/components/scanners/BarScanner'
import rules from '@/mixins/rules'
import record from '@/mixins/record'

export default {
  components: { QrScanner, BarScanner },
  mixins: [rules, record],
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gpsGateModels: ['GGT100', 'GGB100'],
      gateSelected: '',
      imeiScanned: '',
      nanolinkQrScanned: '',
      phoneBarcodeScanned: '',
      step: 1,
      gateStatus: false,
      saving: false,
      saved: false,
    }
  },
  watch: {
    dialog(val) {
      if (!val) this.reset() && this.$emit('closeDialog')
    },
  },
  methods: {
    reset() {
      this.gateSelected = ''
      this.imeiScanned = ''
      this.nanolinkQrScanned = ''
      this.phoneBarcodeScanned = ''
      this.step = 1
      this.gateStatus = false
      this.saved = false
    },
    codeAccepted(value) {
      this.phoneBarcodeScanned = value
    },
    async createGPSGate() {
      this.saving = true
      const gate = {
        trackerAdditionalInfo: {
          teltonikaAdditionalInfo: {
            trackerModel: this.gateSelected,
            iCCID: this.phoneBarcodeScanned,
          },
        },
        vID: this.nanolinkQrScanned,
        pID: this.imeiScanned,
      }

      this.gateStatus = await this.$store.dispatch('createGPSGate', {
        type: 'GPSGATE',
        gpsGates: [gate],
      })

      if (this.gateStatus) {
        this.saving = false
        this.saved = true
      }

      this.$refs.form.reset()
      this.$refs.form.resetValidation()
    },
  },
}
</script>

