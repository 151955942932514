<template>
  <div style="max-width: 500px; margin: 0 auto;">
    <v-breadcrumbs>
      <v-breadcrumbs-item exact :to="{name: 'Batches'}">Batches</v-breadcrumbs-item>
      <v-breadcrumbs-item>
        <template v-if="id">Edit batch</template>
        <template v-else>Create batch</template>
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="!loading">
      <v-form @submit.prevent="submit" v-model="valid" ref="form">
        <v-card-title>
          <div class="headline">
            <template v-if="id">Edit batch</template>
            <template v-else>Create batch</template>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field :disabled="saving" v-model="form.description" label="Description"></v-text-field>

          <v-menu
            ref="menu"
            :close-on-content-click="false"
            v-model="menu"
            :return-value.sync="form.productionDate"
            transition="scale-transition"
            min-width="290px"
            offset-y
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                v-on="on"
                v-model="form.productionDate"
                label="Select date of production *"
                prepend-icon="event"
                readonly
                :rules="[rules.required]"
                :disabled="saving"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="form.productionDate"
              @input="$refs.menu.save(form.productionDate)"
            ></v-date-picker>
          </v-menu>

          <v-text-field
            type="number"
            :rules="[rules.required]"
            :disabled="saving"
            v-model="form.amount"
            label="Amount *"
          ></v-text-field>

          <v-checkbox label="Active" v-model="form.active"></v-checkbox>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="saving"
            type="submit"
            color="success"
            text
            :disabled="!valid"
          >Save {{buttonAppend}}</v-btn>
          <v-btn :disabled="saving" text :to="{name: 'Batches'}" exact color="info">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-progress-linear v-else indeterminate></v-progress-linear>
  </div>
</template>

<script>
import rules from '@/mixins/rules'
import preventLeave from '@/mixins/preventLeave'

export default {
  mixins: [rules, preventLeave],
  data() {
    return {
      loading: true,
      valid: false,
      id: null,
      saving: false,
      menu: false,
      version: 0,
      form: {
        description: '',
        productionDate: '',
        amount: null,
        active: false
      }
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.saving = true
        this.preventLeave = false
        await this.$store.dispatch('saveBatch', {
          productionDate: this.form.productionDate,
          productDescription: this.form.description,
          amount: this.form.amount,
          active: this.form.active,
          id: this.id,
          version: this.version
        })
        this.saving = false
        this.$router.push({ name: 'Batches' })
      }
    },
    async getBatch() {
      let res = await this.$store.dispatch('getBatch', {
        id: this.id
      })

      this.form.productionDate = res.productionDate.split('T')[0]
      this.form.description = res.productDescription
      this.form.amount = res.amount
      this.form.active = res.active
      this.version = res.version
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      await this.getBatch()
    }

    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>
