<template>
  <div style="max-width: 500px; margin: 0 auto;">
    <v-breadcrumbs>
      <v-breadcrumbs-item exact :to="{name: 'Locations'}">Locations</v-breadcrumbs-item>
      <v-breadcrumbs-item>
        <template v-if="id">Edit location</template>
        <template v-else>Create location</template>
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="!loading">
      <v-form @submit.prevent="submit" v-model="valid" ref="form">
        <v-card-title>
          <div class="headline">
            <template v-if="id">Edit location</template>
            <template v-else>Create location</template>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="form.name" label="Name"></v-text-field>
          <v-checkbox disabled v-model="form.headQuarter" label="Headquarter"></v-checkbox>
          <v-text-field v-model="form.address1" label="Address"></v-text-field>
          <v-text-field v-model="form.address2" label="Address (line 2)"></v-text-field>
          <v-text-field v-model="form.city" label="City"></v-text-field>
          <v-text-field v-model="form.zipCode" label="ZIP"></v-text-field>
          <v-text-field v-model="form.region" label="Region"></v-text-field>
          <v-text-field v-model="form.country" label="Country"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="saving"
            type="submit"
            color="success"
            :disabled="!valid"
          >Save {{buttonAppend}}</v-btn>
          <v-btn :disabled="saving" :to="{name: 'Locations'}" exact color="info">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-progress-linear v-else indeterminate></v-progress-linear>
  </div>
</template>

<script>
import rules from "@/mixins/rules"
import preventLeave from "@/mixins/preventLeave"

export default {
  mixins: [rules, preventLeave],
  data() {
    return {
      loading: true,
      valid: false,
      id: null,
      saving: false,
      version: 0,
      form: {
        name: "",
        headQuarter: false,
        address1: "",
        address2: "",
        address3: "",
        city: "",
        zipCode: "",
        region: "",
        country: ""
      }
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.saving = true
        this.preventLeave = false
        await this.$store.dispatch("saveLocation", {
          ...this.form,
          id: this.id,
          version: this.version
        })
        this.saving = false
        this.$router.push({ name: "Locations" })
      }
    },
    async getLocation() {
      let res = await this.$store.dispatch("getLocation", {
        id: this.id
      })

      this.form.name = res.name
      this.form.address1 = res.address1
      this.form.address2 = res.address2
      this.form.city = res.city
      this.form.zipCode = res.zipCode
      this.form.region = res.region
      this.form.headQuarter = res.headQuarter
      this.form.country = res.country
      this.version = res.version
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      await this.getLocation()
    }

    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>
