<template>
  <div style="max-width: 500px; margin: 0 auto;">
    <AddFile ref="addFile" @fileAdded="addFile"></AddFile>

    <v-breadcrumbs>
      <v-breadcrumbs-item exact :to="{name: 'Packages'}">Packages</v-breadcrumbs-item>
      <v-breadcrumbs-item>
        <template v-if="id">Edit package</template>
        <template v-else>Create package</template>
      </v-breadcrumbs-item>
    </v-breadcrumbs>

    <v-card v-if="!loading">
      <v-form @submit.prevent="submit" v-model="valid" ref="form">
        <v-card-title>
          <div class="headline">
            <template v-if="id">Edit package</template>
            <template v-else>Create package</template>
          </div>
        </v-card-title>
        <v-card-text>
          <v-text-field
            min="1"
            type="number"
            :disabled="saving || !!id"
            v-model="form.amount"
            label="Number of NanoLinks *"
            :rules="[rules.required]"
          ></v-text-field>
          <LocationPicker v-model="form.location"></LocationPicker>

          <v-input :value="files">
            <v-flex>
              <div style="border: 1px dashed #ddd;">
                <template v-if="oldFiles.length === 0">
                  <div class="body-2 text-xs-center pa-2">No existing files</div>
                </template>
                <template v-else>
                  <div class="body-2 text-xs-center pt-2">{{oldFiles.length}} existing file(s)</div>
                  <v-list two-line>
                    <template v-for="(file, index) in oldFiles">
                      <v-list-item :key="index">
                        <v-list-item-avatar>
                          <v-chip color="primary" text-color="white">{{ file.manifest.length }}</v-chip>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ file.batch.batchDescription }}</v-list-item-title>
                          <v-list-item-subtitle>{{ formatDate(file.batch.creationTime) }}</v-list-item-subtitle>
                        </v-list-item-content>
                      </v-list-item>
                      <v-divider v-if="index + 1 < oldFiles.length" :key="index"></v-divider>
                    </template>
                  </v-list>
                </template>
              </div>

              <v-btn color="primary" large block @click="$refs.addFile.open()">Add file</v-btn>

              <div style="border: 1px dashed #ddd;">
                <template v-if="files.length === 0">
                  <div class="body-2 text-xs-center pa-2">No files added</div>
                </template>
                <template v-else>
                  <div class="body-2 text-xs-center pt-2">{{files.length}} file(s) added</div>
                  <v-list two-line>
                    <template v-for="(file, index) in files">
                      <v-list-item :key="index">
                        <v-list-item-avatar>
                          <v-chip color="primary" text-color="white">{{ file.nanolinks.length }}</v-chip>
                        </v-list-item-avatar>

                        <v-list-item-content>
                          <v-list-item-title>{{ file.batchDescription }}</v-list-item-title>
                          <v-list-item-subtitle>{{ formatDate(file.batchDate) }}</v-list-item-subtitle>
                        </v-list-item-content>

                        <v-list-item-action @click="removeFile(index)">
                          <v-btn icon ripple>
                            <v-icon color="error">clear</v-icon>
                          </v-btn>
                        </v-list-item-action>
                      </v-list-item>
                      <v-divider v-if="index + 1 < files.length" :key="index"></v-divider>
                    </template>
                  </v-list>
                </template>
              </div>
            </v-flex>
          </v-input>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :loading="saving"
            type="submit"
            color="success"
            :disabled="!valid"
          >Save {{buttonAppend}}</v-btn>
          <v-btn :disabled="saving" :to="{name: 'Batches'}" exact color="info">Cancel</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-progress-linear v-else indeterminate></v-progress-linear>
  </div>
</template>

<script>
import rules from "@/mixins/rules"
import preventLeave from "@/mixins/preventLeave"
import AddFile from "@/components/AddFile"
import formatDate from "@/mixins/formatDate"
import LocationPicker from "@/components/common/LocationPicker"

export default {
  mixins: [rules, preventLeave, formatDate],
  components: {
    AddFile,
    LocationPicker
  },
  data() {
    return {
      dialog: false,
      loading: true,
      valid: false,
      id: null,
      saving: false,
      menu: false,
      version: 0,
      oldFiles: [],
      form: {
        amount: null,
        location: ""
      },
      files: []
    }
  },
  methods: {
    async submit() {
      if (this.$refs.form.validate()) {
        this.saving = true
        this.preventLeave = false

        let packageId, version

        if (this.id) {
          packageId = this.id
          version = this.version
        } else {
          let res = await this.$store.dispatch("savePackage", {
            nominalCount: this.form.amount
          })
          packageId = res.packageId
          version = res.version
        }

        await this.$store.dispatch("relocatePackage", {
          packageId,
          newLocationId: this.form.location,
          version
        })

        for (const file of this.files) {
          await this.$store.dispatch("saveFile", {
            packageId: packageId,
            batchId: file.batchId,
            version: version,
            fileData: file.nanolinks.map(item => {
              return {
                iD: item.ID,
                rSSI: item.RSSI,
                iOSCompatible: item.iOSCompatible,
                lastContact: item.LastContact
              }
            })
          })
        }

        this.saving = false

        this.$store.dispatch("notify", {
          message: "Package assigned",
          color: "success"
        })

        this.$router.push({ name: "Packages" })
      }
    },
    async getPackage() {
      let res = await this.$store.dispatch("getPackage", {
        id: this.id
      })

      this.oldFiles = res.files || []
      this.form.location = res.locationId
      this.form.amount = res.nominalCount
      this.version = res.version
    },
    addFile(file) {
      this.files.push(file)
    },
    removeFile(index) {
      this.files.splice(index, 1)
    }
  },
  async mounted() {
    if (this.$route.params.id) {
      this.id = this.$route.params.id
      await this.getPackage()
    }

    this.$nextTick(() => {
      this.loading = false
    })
  }
}
</script>

<style scoped>
</style>
