import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"width":"500","persistent":_vm.saving},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('div',{staticClass:"title"},[_vm._v("Assign package to customer")])]),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VSelect,{attrs:{"disabled":_vm.saving,"item-text":"companyName","return-object":"","item-value":"id","items":_vm.customers,"label":"Select customer *","rules":[_vm.rules.required]},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}})],1)],1),_c(VCardActions,[_c(VBtn,{attrs:{"text":"","color":"success","loading":_vm.saving,"disabled":!_vm.valid},on:{"click":_vm.assign}},[_vm._v("Assign")]),_c(VBtn,{attrs:{"text":"","color":"warning","disabled":_vm.saving},on:{"click":function($event){_vm.dialog = false}}},[_vm._v("Cancel")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }