import graphql from '@/imports/graphql'

export default {
  actions: {
    async createGPSGate(context, params) {
      let res = await graphql(
        `
      mutation createGPSGate($gpsGates: [PIDMapMutation], $type: String!) {
        core_addmapfile(
          idmaps: $gpsGates,
          type: $type,
          token: "${context.rootState.token}"
        ) {
          result
          errors {
            message
          }
        }
      }
    `,
        params
      )

      return res.data.core_addmapfile
    },
    async addTrackers(context, params) {
      let res = await graphql(
        `
        mutation AddTrackers($trackers: [PIDMapMutation], $type: String!) {
          core_addmapfile(
            idmaps: $trackers,
            type: $type,
            token: "${context.rootState.token}"
          ) {
            result
            errors {
              message
            }
          }
        }
      `,
        params
      )
      return res.data.core_addmapfile.result
    },
    async getTrackers(context, params) {
      let res = await graphql(
        `
        query GetTrackers {
          core_listidmap(
            type: "${params.type}",
            token: "${context.rootState.token}"
          ) {
            result {
              additionalInfo {
                ... on QTeltonikaAdditionalInfo {
                    iCCID
                    trackerModel
                  }
              }                                    
              id
              pID
              vID
              customerId
              trackerType
            }
            errors {
              message
            }
          }
        }
      `,
        params
      )
      return res.data.core_listidmap.result
    },
    async isGateConnected(context, params) {
      let res = await graphql(
        `
          query isGateConnected($token: String!) {
            core_isgateconnected(token: $token) {
              errors {
                message
              }
              result
            }
          }
        `,
        {
          token: context.rootState.token
        }
      )
      return res.data.core_isgateconnected
    }
  }
}
