<template>
  <div>
    <v-btn color="primary" to="/batches/create">Create batch</v-btn>

    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="batches"
      class="elevation-1"
      @update="pagination"
      :search="search"
      item-key="id"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.id }}</td>
          <td>{{ props.item.productDescription }}</td>
          <td class="text-xs-right">{{ props.item.amount }}</td>
          <td class="text-xs-right">{{ props.item.usedCount }}</td>
          <td class="text-xs-right">{{ props.item.availableCount }}</td>
          <td class="text-xs-right">{{ props.item.productionDate }}</td>
          <td class="text-xs-right">{{ props.item.active }}</td>
        </tr>
      </template>

      <template slot="expand" slot-scope="props">
        <v-card text class="pa-2">
          <v-btn color="warning" :to="{name: 'BatchEdit', params: {id: props.item.id}}">Edit</v-btn>
          <v-btn @click="deleteBatch(props.item.id, props.item.version)" color="error">Delete</v-btn>
        </v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "ID", value: "id" },
        { text: "Description", value: "productDescription" },
        { text: "Amount", value: "amount", align: "right" },
        { text: "Used", value: "usedCount", align: "right" },
        { text: "Available", value: "availableCount", align: "right" },
        { text: "Production date", value: "productionDate", align: "right" },
        { text: "Active", value: "active", align: "right" }
      ],
      batches: [],
      pagination: {
        sortBy: "productionDate",
        rowsPerPage: 25
      }
    }
  },
  mounted() {
    this.getBatches()
  },
  methods: {
    async getBatches() {
      let batches = await this.$store.dispatch("getBatches")
      this.batches = batches || []
    },
    async deleteBatch(id, version) {
      let res = await this.$root.$confirmDelete(
        "Delete batch",
        `Are you sure you want to delete the batch?`
      )
      if (res) {
        await this.$store.dispatch("deleteBatch", {
          id: id,
          version: version
        })
        this.getBatches()
      }
    }
  }
}
</script>
