export default {
	methods: {
		isExisting (record) {
			if(record !== null && record !== undefined) return true
			else false
		},
		customerId(record) {
			if (!record) return false
			if (record.customerId !== undefined && record.customerId !== null) {
				return record.customerId
			}
		},
		logStatus(record) {
			if (!record) return ''
			if (record.log != null && record.log !== '') return record.log
		},
		errorStatus(record) {
			if (!record) return ''
			if (record.errors !== undefined && record.errors != null && Array.isArray(record.errors) && record.errors.length > 0 && 'message' in record.errors[0]) {
				return record.errors[0].message
			}

		}
	}

}