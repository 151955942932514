<template>
  <div>
    <v-btn color="primary" to="/users/create">Create user</v-btn>

    <v-text-field
      v-model="search"
      append-icon="search"
      label="Search"
      single-line
      hide-details
      class="mb-3"
    ></v-text-field>

    <v-data-table
      :headers="headers"
      :items="users"
      class="elevation-1"
      @update="pagination"
      :search="search"
      item-key="id"
      :loading="loading"
    >
      <template slot="items" slot-scope="props">
        <tr @click="props.expanded = !props.expanded">
          <td>{{ props.item.fullName }}</td>
          <td>{{ props.item.email }}</td>
        </tr>
      </template>

      <template slot="expand" slot-scope="props">
        <v-card class="pa-2">
          <v-btn color="warning" :to="{name: 'UserEdit', params: {id: props.item.id}}">Edit</v-btn>
          <v-btn @click="deleteUser(props.item.id, props.item.version)" color="error">Delete</v-btn>
        </v-card>
      </template>
    </v-data-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      loading: true,
      users: [],
      headers: [
        { text: "Name", value: "fullName" },
        { text: "Email", value: "email" }
      ],
      pagination: {
        sortBy: "fullName",
        rowsPerPage: 25
      }
    }
  },
  mounted() {
    this.getUsers()
  },
  methods: {
    async getUsers() {
      this.loading = true
      this.users = await this.$store.dispatch("getUsers")
      this.loading = false
    },
    async deleteUser(id, version) {
      let res = await this.$root.$confirmDelete(
        "Delete user",
        `Are you sure you want to delete the user?`
      )
      if (res) {
        await this.$store.dispatch("deleteUser", {
          id: id,
          version: version
        })
        this.getUsers()
      }
    }
  }
}
</script>
