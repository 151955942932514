<template>
  <v-snackbar top :color="notification.color" v-model="notification.show">
    {{ notification.message }}
  </v-snackbar>
</template>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState(['notification']),
  },
}
</script>
